import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Card,
  CardContent,
  Container,
  DialogContentText,
  Stack,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { FarmJobType } from '../../types/Journal'
import ConfigurationSkeletons from './ConfigurationSkeletons'


const FarmJobTypeView: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [farmJobTypes, setFarmJobTypes] = useState<FarmJobType[]>([])  
  const { showError, showSuccess } = useSnackbar()
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [deletingJobTypeId, setDeletingJobTypeId] = useState<number | null>(null)
  const { auth } = useAuth()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getFarmJobTypes(controller)
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getFarmJobTypes = async (controller: AbortController) => {
    try {
      setIsLoading(true)
      const response = await axiosPrivate.get(endpoints.getFarmJobTypes, {
        signal: controller.signal,
      })
      setFarmJobTypes(response.data)
      setIsLoading(false)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al cargar los trabajos de las fincas')
      }
    }
  }

  const handleEditRow = (id: GridRowId) => () => {
    navigate(`/trabajos-fincas/editar/${id}`)
  }

  const handleDeleteRow = (id: GridRowId) => () => {
    setDeletingJobTypeId(Number(id))
    setOpenDialog(true)
  }

  const handleOnCloseDelete = () => {
    setOpenDialog(false)
  }

  const handleOnConfirmDelete = async () => {
    if (deletingJobTypeId !== null) {
      await DeleteJobType(Number(deletingJobTypeId))
      setOpenDialog(false)
    }
  }

  const dialogAcceptButton = {
    text: 'Eliminar',
    action: handleOnConfirmDelete,
  }

  const dialogCancelButton = {
    text: 'Cancelar',
    action: handleOnCloseDelete,
  }

  const DeleteJobType = async (jobTypeId: number) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendDeleteJobTypeRequest = async () => {
      try {
        const url = endpoints.deleteFarmJobType
          .replace('{farmJobTypeId}', jobTypeId.toString())
          .replace('{deletedByUserEmail}', auth.user?.email || '')

        const response = await axiosPrivate.delete(url, {
          signal: controller.signal,
        })

        if (response.status === 200) {
          showSuccess('Tipo de trabajo eliminado correctamente!')
          navigate('/trabajos-fincas')
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error eliminar el tipo de trabajo ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
        getFarmJobTypes(controller)
      }
    }

    SendDeleteJobTypeRequest()
  }

  const columns: GridColDef<FarmJobType>[] = [
    {
      headerName: 'Trabajo',
      field: 'jobType',
      valueGetter: (value, row) => row.name,
      minWidth: 140,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 55,
      getActions: (params: GridRowParams<FarmJobType>) => [
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<EditIcon />}
          label='Editar'
          className='textPrimary'
          onClick={handleEditRow(params.row.id)}
          color='inherit'
        />,
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<DeleteIcon />}
          label='Eliminar'
          onClick={handleDeleteRow(params.row.id)}
          color='inherit'
        />,
      ],
    },
  ]

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>
              Trabajos que se realizan en las fincas
            </Typography>
          </Grid>

          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              component={NavLink}
              to='/trabajos-fincas/agregar'
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Agregar Trabajo
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {isLoading ? (
              <ConfigurationSkeletons count={5} />
            ) : (
              <Grid size={12}>
                <DataGrid
                  rows={farmJobTypes}
                  columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  density='compact'
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 300,
                        placeholder: 'Buscar',
                      },
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <DynamicDialog
        openDialog={openDialog}
        dialogTitle='Confirmar eliminación'
        onCloseAction={handleOnCloseDelete}
        buttonAccept={dialogAcceptButton}
        buttonCancel={dialogCancelButton}
      >
        <DialogContentText id='generic-dialog-description'>
          ¿Realmente quiere eliminar este tipo de trabajo?
        </DialogContentText>
      </DynamicDialog>
    </Container>
  )
}

export default FarmJobTypeView
