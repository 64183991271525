import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { DataGrid } from '@mui/x-data-grid'
import { GridColDef } from '@mui/x-data-grid/models'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import { Tenant } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { fNumber } from '../../utils/format-number'
import { fDateShort, formatDateTimeCustom } from '../../utils/format-time'
import ConfigurationSkeletons from './ConfigurationSkeletons'

type EmployeeJobTypePrice = {
  id: number
  price: number
  createdDate: string
  createdByUserEmail: string
}

 type EmployeeJobTypeDetails = {
    id: number
    name: string
    jobPrice: number | null
    modifiedDate: string
    modifiedByUserEmail: string
    tenants: Tenant[]
    jobPriceHistory: EmployeeJobTypePrice[]
  }

const EmployeeJobTypeDetailsView: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [employeeJobType, setEmployeeJobType] = useState<EmployeeJobTypeDetails | null>(null)
  const { showError } = useSnackbar()

  const getEmployeeJobTypeDetails = async (controller: AbortController) => {
    try {
      const url = endpoints.getEmployeeJobType
      .replace('{employeeJobTypeId}', id || '')
      .replace('{includeJobPriceHistory}', true.toString())

      const response = await axiosPrivate.get(
        url,
        { signal: controller.signal }
      )

      setEmployeeJobType(response.data)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al obtener los detalles del tipo de trabajo')
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const fetchData = async () => {
      if (isMounted) {
        await getEmployeeJobTypeDetails(controller)
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditEmployeeJobType = () => {
    if (employeeJobType) {
      navigate(`/trabajos-empleados/editar/${id}`, { state: { id: id } })
    }
  }

  const columns: GridColDef<EmployeeJobTypePrice>[] = [
    {
      headerName: 'Fecha',
      field: 'name',
      valueGetter: (value, row) => fDateShort(row.createdDate),
      minWidth: 120,
      flex: 1,      
    },
    {
      headerName: 'Precio',
      field: 'price',
      minWidth: 125,
      flex: 1,
      valueGetter: (value) => fNumber(value),
    },
    {
      headerName: 'Usuario',
      field: 'createdByUserEmail',
      valueGetter: (value, row) => row.createdByUserEmail,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal', wordBreak: 'break-word', display: 'flex', alignItems: 'center', height: '100%' }}>
          {params.value}
        </div>
      ),
    },    
  ]


  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1)
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Detalles del Trabajo</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Card>
        <CardContent>
          {isLoading ? (
            <ConfigurationSkeletons />
          ) : (
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }}>
                <Typography variant='body1'>
                  <strong>Descripción:</strong> {employeeJobType?.name}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant='body1'>
                  <strong>Precio:</strong> {fNumber(employeeJobType?.jobPrice)}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant='body1'>
                  <strong>Última modificación:</strong>{' '}
                  {formatDateTimeCustom(employeeJobType?.modifiedDate)} (
                  {employeeJobType?.modifiedByUserEmail})
                </Typography>
              </Grid>
              <Grid size={12} container>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant='h6'>Perfiles asociados</Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <List sx={{ padding: 0 }}>
                  {employeeJobType?.tenants.length ? (
                    employeeJobType?.tenants.map((tenant) => (
                      <ListItem key={tenant.id} sx={{ paddingY: 0 }}>
                        <ListItemText primary={tenant.name} />
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant='body2' color='textSecondary'>
                      El tipo de trabajo no tiene perfiles asociados.
                    </Typography>
                  )}
                </List>
              </Grid>
              <Grid size={12} container>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant='h6'>Historial de precios</Typography>
              </Grid>
              <Grid size={12}>
                <DataGrid
                  rows={employeeJobType?.jobPriceHistory}
                  columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  density='compact'                 
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}>
          <Button
            onClick={handleEditEmployeeJobType}
            variant='contained'
            color='inherit'
          >
            Editar
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}

export default EmployeeJobTypeDetailsView
