import { Avatar, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { AxiosError } from 'axios'
import Compressor from 'compressorjs'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Employee, EmployeeGender, getGenderDescription } from '../../types/Employee'
import { constants } from '../contants'

type CreateEmployeeRequest = {
  createdByUserEmail: string
  idDocument: string
  birthDate?: string
  firstName: string
  lastName: string
  address: string
  gender: string
}

const EmployeeCreateView: FC = () => {
  const { auth } = useAuth()
  const { showSuccess, showError } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [idDocument, setIdDocument] = useState<string>('')
  const [birthDate, setBirthDate] = useState<Dayjs | null>(null)
  const [dateError, setDateError] = useState<DateValidationError | null>(null)
  const [isDateEmpty, setIsDateEmpty] = useState<boolean>(birthDate === null)
  const minDate = dayjs().subtract(90, 'year')
  const maxDate = dayjs().subtract(5, 'year')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [idDocumentError, setIdDocumentError] = useState<boolean>(false)
  const [firstNameError, setFirstNameError] = useState<boolean>(false)
  const [lastNameError, setLastNameError] = useState<boolean>(false)
  const [genderError, setGenderError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<boolean>(false)
   const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
   const [selectedImageBytes, setSelectedImageBytes] = useState<File | null>(null)

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       const file = event.target.files?.[0]
       if (file) {
         if (file.size > 1 * 1024 * 1024) {
           // 1MB
           new Compressor(file, {
             quality: 0.4, // Adjust the quality of the compressed image
             maxWidth: 800, // Resize image if it exceeds 1024px
             success(result: Blob) {
               const compressedFile = new File([result], file.name, {
                 type: file.type,
               })
               setSelectedImageBytes(compressedFile)
               setSelectedImageUrl(URL.createObjectURL(compressedFile))
             },
             error(err) {
               console.error('Error al comprimir la imagen:', err)
             },
           })
         } else {
           setSelectedImageBytes(file)
           setSelectedImageUrl(URL.createObjectURL(file))
         }
       }
     }

  const handleDateChange = (newValue: Dayjs | null) => {
    setBirthDate(newValue)
    if (!newValue) {
      setIsDateEmpty(true)
      setDateError(null)
    } else {
      setIsDateEmpty(false)
      setDateError(null)
    }
  }

  const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!idDocument.trim()) {
      setIdDocumentError(true)
      isValid = false
    } else {
      setIdDocumentError(false)
    }

    if (!firstName.trim()) {
      setFirstNameError(true)
      isValid = false
    } else {
      setFirstNameError(false)
    }

    if (!lastName.trim()) {
      setLastNameError(true)
      isValid = false
    } else {
      setLastNameError(false)
    }

    if (!address.trim()) {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }

    if (!gender.trim()) {
      setGenderError(true)
      isValid = false
    } else {
      setGenderError(false)
    }

    return isValid
  }

   const dateErrorMessage = useMemo(() => {
      if (isDateEmpty) return constants.requiredFieldMessage
  
      switch (dateError) {
        case 'maxDate':
          return `La fecha no puede ser mayor a ${maxDate.format('DD-MM-YYYY')}`
        case 'minDate':
          return `La fecha no puede ser menor a ${minDate.format('DD-MM-YYYY')}`
        case 'invalidDate':
          return 'La fecha ingresada no es válida'
        default:
          return ''
      }
    }, [dateError, isDateEmpty, maxDate, minDate])

 
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()   

    if (!validateRequiredFields()) {
      showError('Por favor, complete los campos requeridos.')
      return
    }

    const newEmployee: CreateEmployeeRequest = {
      createdByUserEmail: auth.user?.email || '',
      idDocument,
      birthDate: birthDate?.format('YYYY-MM-DD') || '',
      firstName,
      lastName,
      address,
      gender,
    }
   
    CreateEmployee(newEmployee)
  }  

  const CreateEmployee = async (newEmployee: CreateEmployeeRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const formData = new FormData()
    formData.append('CreatedByUserEmail', newEmployee.createdByUserEmail)
    formData.append('IdDocument', newEmployee.idDocument)
    if (newEmployee.birthDate)
      formData.append('BirthDate', newEmployee.birthDate)
    formData.append('FirstName', newEmployee.firstName)
    formData.append('LastName', newEmployee.lastName)
    formData.append('Address', newEmployee.address)
    formData.append('Gender', newEmployee.gender)

    if (selectedImageBytes) {
      formData.append('EmployeeImage', selectedImageBytes)
    }

    try {
      const response = await axiosPrivate.post(
        endpoints.createEmployee,
        formData,
        {
          signal: controller.signal,
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      if (response.status === 200) {
        showSuccess('Empleado creado correctamente!')
        const createdEmployee = response.data as Employee    
        navigate(`/empleados/editar/${createdEmployee.id}`, { state: { id: createdEmployee.id } })
      }
    } catch (err: any) {
      const error = err as AxiosError<ApiError>
      if (error.name !== 'CanceledError') {
        showError(
          'Error al crear el empleado: ' + error?.response?.data?.detail
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate('/empleados')
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Agregar empleado</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Grid container sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, md: 3.8 }} sx={{ mr: { md: 2 }, mb: { xs: 3 } }}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ mt: 2 }}
              >
                <Avatar
                  src={selectedImageUrl || undefined}
                  alt='User Avatar'
                  sx={{ width: 180, height: 180, mb: 2 }}
                />
                <input
                  accept='image/*'
                  type='file'
                  id='image-upload'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <label htmlFor='image-upload'>
                  <Button variant='contained' color='inherit' component='span'>
                    Seleccionar imagen
                  </Button>
                </label>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                width='100%'
                sx={{ mt: 2 }}
              ></Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, md: 8 }}>
        <Box
          component='form'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {isLoading && <Loading centered={true} />}
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    label='Identificación'
                    value={idDocument}
                    onChange={(e) => setIdDocument(e.target.value)}
                    required
                    error={idDocumentError}
                    helperText={
                      idDocumentError ? constants.requiredFieldMessage : ''
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    label='Fecha de nacimiento'
                    value={birthDate}
                    onChange={handleDateChange}
                    sx={{ width: '100%' }}
                    onError={(newError) => setDateError(newError)}
                    slotProps={{
                      textField: {
                        error: isDateEmpty || dateError != null,
                        helperText: dateErrorMessage,
                      },
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    label='Nombre'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    error={firstNameError}
                    helperText={
                      firstNameError ? constants.requiredFieldMessage : ''
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    label='Apellidos'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    error={lastNameError}
                    helperText={
                      lastNameError ? constants.requiredFieldMessage : ''
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <FormControl fullWidth error={genderError}>
                    <InputLabel id='gender-label'>Género</InputLabel>
                    <Select
                      labelId='gender-label'
                      value={gender}
                      label='Género'
                      onChange={(e) =>
                        setGender(e.target.value as EmployeeGender)
                      }
                      required
                    >
                      {Object.values(EmployeeGender).map((gender) => (
                        <MenuItem key={gender} value={gender}>
                          {getGenderDescription(gender)}
                        </MenuItem>
                      ))}
                    </Select>
                    {genderError && (
                      <FormHelperText>
                        {constants.requiredFieldMessage}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    label='Dirección'
                    name='address'
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    value={address}
                    multiline
                    maxRows={3}
                    error={addressError}
                    helperText={
                      addressError ? constants.requiredFieldMessage : ''
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button type='submit' variant='contained' color='inherit'>
                Guardar
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Grid>
    </Container>
  )
}

export default EmployeeCreateView
