import React, { FC } from 'react'

const FarmProjectDetailsView: FC = () => {
    return (
        <div>
            <h1>Farm Project Details</h1>
            <p>Welcome to the Farm Project Details view. More information will be added here soon.</p>
        </div>
    )
}

export default FarmProjectDetailsView