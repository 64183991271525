import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import EmployeeCreateView from '../../sections/Employee/EmployeeCreateView'

const EmployeeCreate = () => {    
    return (
      <>
        <Helmet>
          <title>Empleado Crear | Sistema Planilla</title>
        </Helmet>
  
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <EmployeeCreateView  />
        </MsalAuthenticationTemplate>
      </>
    )

}

export default EmployeeCreate