import ForestIcon from '@mui/icons-material/Forest'
import { Avatar } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import Compressor from 'compressorjs'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import TenantSelector from '../../components/TenantSelector'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Farm } from '../../types/Farm'
import { constants } from '../contants'


type CreateFarmRequest = {
  createdByUserEmail: string
  name: string
  propertySize: string
  address: string
  tenantId: number
}

const FarmCreateView: FC = () => {
  const { auth } = useAuth()
  const { showSuccess, showError } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { selectedTenant } = useAuth()
  const [name, setName] = useState<string>('')
  const [propertySize, setPropertySize] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [nameError, setNameError] = useState<boolean>(false)
  const [propertySizeError, setPropertySizeError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<boolean>(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
  const [selectedImageBytes, setSelectedImageBytes] = useState<File | null>(null)

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        // 1MB
        new Compressor(file, {
          quality: 0.4, // Adjust the quality of the compressed image
          maxWidth: 800, // Resize image if it exceeds 1024px
          success(result: Blob) {
            const compressedFile = new File([result], file.name, {
              type: file.type,
            })
            setSelectedImageBytes(compressedFile)
            setSelectedImageUrl(URL.createObjectURL(compressedFile))
          },
          error(err) {
            console.error('Error al comprimir la imagen:', err)
          },
        })
      } else {
        setSelectedImageBytes(file)
        setSelectedImageUrl(URL.createObjectURL(file))
      }
    }
  }

  const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!name.trim()) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    if (!propertySize.trim()) {
      setPropertySizeError(true)
      isValid = false
    } else {
      setPropertySizeError(false)
    }

    if (!address.trim()) {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }

    return isValid
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!validateRequiredFields()) {
      showError('Por favor, complete los campos requeridos.')
      return
    }

    const newFarm: CreateFarmRequest = {
      createdByUserEmail: auth.user?.email || '',
      name,
      propertySize,
      address,
      tenantId: selectedTenant?.id || 0,
    }

    CreateFarm(newFarm)
  }

  const CreateFarm = async (newFarm: CreateFarmRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const formData = new FormData()    
    formData.append('CreatedByUserEmail', newFarm.createdByUserEmail)
    formData.append('Name', newFarm.name)    
    formData.append('Address', newFarm.address)
    formData.append('PropertySize', newFarm.propertySize.toString())
    formData.append('TenantId', newFarm.tenantId.toString())
    if (selectedImageBytes) {
      formData.append('FarmImage', selectedImageBytes)
    }

     try {
       const response = await axiosPrivate.post(endpoints.createFarm, formData, {
         signal: controller.signal,
         headers: { 'Content-Type': 'multipart/form-data' },
       })

       if (response.status === 200) {
         showSuccess('Finca creata correctamente!')
         const createdFarm = response.data as Farm                     
         navigate(`/fincas/detalles/${createdFarm.id}`, { state: { id: createdFarm.id } })
       }
     } catch (err: any) {
       const error = err as AxiosError<ApiError>
       if (error.name !== 'CanceledError') {
         showError(
           'Error al crear la finca: ' + error?.response?.data?.detail
         )
       }
     } finally {
       setIsLoading(false)
     }    
  }

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate('/fincas')
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Agregar finca</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Grid container sx={{ width: '100%' }}>
        <Grid size={{ xs: 12, md: 3.8 }} sx={{ mr: { md: 2 }, mb: { xs: 3 } }}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ mt: 2 }}
              >
                <Avatar
                  src={selectedImageUrl || undefined}
                  alt='Farm Avatar'
                  sx={{ width: 180, height: 180, mb: 2 }}
                >
                  {!selectedImageUrl && <ForestIcon sx={{ fontSize: 80 }} />}
                </Avatar>
                <input
                  accept='image/*'
                  type='file'
                  id='image-upload'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <label htmlFor='image-upload'>
                  <Button variant='contained' color='inherit' component='span'>
                    Seleccionar imagen
                  </Button>
                </label>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <Box
            component='form'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {isLoading && <Loading centered={true} />}
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TenantSelector
                      formControlSize='medium'
                      includeAllTenantsOption={false}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Nombre finca'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      error={nameError}
                      helperText={
                        nameError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Tamaño (hectáreas)'
                      value={propertySize}
                      onChange={(e) => setPropertySize(e.target.value)}
                      required
                      error={propertySizeError}
                      helperText={
                        propertySizeError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Dirección'
                      name='address'
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      value={address}
                      multiline
                      maxRows={3}
                      error={addressError}
                      helperText={
                        addressError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button type='submit' variant='contained' color='inherit'>
                  Guardar
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FarmCreateView
