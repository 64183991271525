import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import Grid from '@mui/material/Grid2'
import { FC, useCallback, useEffect, useState } from "react"
import { Tenant, useAuth } from "../../context/AuthProvider"
import { removeLocalStorageItem, setLocalStorageItem } from '../../utils/localStorageUtils'

interface TenantSelectorProps {
    includeAllTenantsOption?: boolean   
    formControlSize?: 'small' | 'medium'
}

const TenantSelector: FC<TenantSelectorProps> = ({
    includeAllTenantsOption = true,
    formControlSize = 'small'
}) => {
    const { auth, selectedTenant, setSelectedTenant, setIsAllTenantsSelected, isAllTenantsSelected } = useAuth()
    const { tenants } = auth
    const [availableTenants, setAvailableTenants] = useState<Tenant[]>([])

    const handelSetSelectedTenant = useCallback(
      (tenant: Tenant | null) => {
        const isAllTenantsSelected = tenant?.id === -1
        setSelectedTenant(tenant)

        if (isAllTenantsSelected) {
          removeLocalStorageItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_SELECTED_TENANT as string)
        } else {
          setLocalStorageItem(process.env.REACT_APP_LOCAL_STORAGE_KEY_SELECTED_TENANT as string, JSON.stringify(tenant), 2)
        }

        setIsAllTenantsSelected(isAllTenantsSelected)
      },
      [setIsAllTenantsSelected, setSelectedTenant]
    )

    useEffect(() => {        
        if (tenants && tenants.length === 1) {
            handelSetSelectedTenant(tenants[0])
            setAvailableTenants(tenants)
        } else if (tenants && tenants.length > 1) {            
            const allTenants = includeAllTenantsOption ? [{ id: -1, name: 'Todos' }, ...tenants] : tenants
            setAvailableTenants(allTenants)            
            if(!selectedTenant || (!includeAllTenantsOption && isAllTenantsSelected)) {
                handelSetSelectedTenant(allTenants[0])            
            }            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenants])

    return (
        <Grid>
            <FormControl
                sx={{
                    minWidth: 200,
                }}
                size={formControlSize}
                fullWidth
            >
                <InputLabel id='perfil-select-label'>Perfil</InputLabel>
                <Select
                    labelId='perfil-select-label'
                    id='select-perfil'
                    value={availableTenants.some(tenant => tenant.id === selectedTenant?.id) ? selectedTenant?.id : ''}
                    label='Perfil'
                    onChange={(event) => {
                        const selectedId = event.target.value
                        const tenant = availableTenants.find((t) => t.id === selectedId) || null
                        handelSetSelectedTenant(tenant)
                    }}
                >
                    {availableTenants.map((tenant) => (
                        <MenuItem key={tenant.id} value={tenant.id}>
                            {tenant.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    )
}

export default TenantSelector