import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmJobTypeCreateView from '../../sections/Configuration/FarmJobTypeCreateView'

const FarmJobTypeCreate = () => {
  return (
    <>
      <Helmet>
        <title>Agregar Trabajo | Sistema Planilla</title>
      </Helmet>
      
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <FarmJobTypeCreateView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default FarmJobTypeCreate
