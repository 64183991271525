import { format, formatDistanceToNow, getTime } from 'date-fns'
import { es } from 'date-fns/locale'

function toUTC(date) {
  const dateObj = new Date(date)
  return new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), dateObj.getHours(), dateObj.getMinutes(), dateObj.getSeconds()))
}

export function formatFullDateWithDay(date) {
  const fm = 'iiii dd MMM yyyy'
  return date ? format(new Date(date), fm, { locale: es }) : ''
}

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy'
  return date ? format(toUTC(date), fm) : ''
}

export function fDateShort(date) {
  const fm = 'dd/MM/yyyy'
  return date ? format(toUTC(date), fm) : ''
}

export function fDateShortNotUtc(date) {
  const fm = 'dd/MM/yyyy'
  return date ? format(new Date(date), fm) : ''
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p'
  return date ? format(toUTC(date), fm) : ''
}

export function formatDateTimeCustom(date) {
  const fm = 'dd/MM/yyyy hh:mm a'
  return date ? format(toUTC(date), fm) : ''
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : ''
}

export function fToNow(date) {
  return date ? formatDistanceToNow(toUTC(date), { addSuffix: true }) : ''
}

export function getCurrentUTCDateTime() {
  const now = new Date();
  const pad = (num, size = 2) => String(num).padStart(size, '0');

  const year = now.getUTCFullYear();
  const month = pad(now.getUTCMonth() + 1);
  const day = pad(now.getUTCDate());
  const hours = pad(now.getUTCHours());
  const minutes = pad(now.getUTCMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}