import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmEditView from '../../sections/Farm/FarmEditView'

const FarmEdit = () => {    
    return (
      <>
        <Helmet>
          <title>Finca Editar | Sistema Planilla</title>
        </Helmet>
  
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <FarmEditView  />
        </MsalAuthenticationTemplate>
      </>
    )

}

export default FarmEdit