import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmProjectCreateView from '../../sections/Farm/FarmProjectCreateView'

const FarmProjectCreate = () => {
  return (
    <>
      <Helmet>
        <title>Agregar Proyecto | Sistema Planilla</title>
      </Helmet>
      
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <FarmProjectCreateView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default FarmProjectCreate
