import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmJobTypeEditView from '../../sections/Configuration/FarmJobTypeEditView'

const FarmJobTypeEdit = () => {    
    return (
      <>
        <Helmet>
          <title>Trabajo Editar | Sistema Planilla</title>
        </Helmet>
  
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <FarmJobTypeEditView  />
        </MsalAuthenticationTemplate>
      </>
    )

}

export default FarmJobTypeEdit