import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { DailyWorkReportProvider } from '../../context/DailyWorkReportProvider'
import CreateDailyWorkReportView from '../../sections/DailyWorkReport/CreateDailyWorkReportView'
import { Farm } from '../../types/Farm'
import { JournalDailyWorkDetail } from '../../types/Journal'

const CreateDailyWorkReport = () => {
  const location = useLocation()  
  const { selectedFarm } = location.state as { selectedFarm: Farm } || {}
  const { initialJournalDailyWorkDetails } = location.state as { initialJournalDailyWorkDetails: JournalDailyWorkDetail[] } || {}
  return (
    <>
      <Helmet>
        <title>Reportar Trabajo | Sistema Planilla</title>
      </Helmet>

      <DailyWorkReportProvider>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <CreateDailyWorkReportView
            initialFarm={selectedFarm}
            initialJournalDailyWorkDetails={initialJournalDailyWorkDetails}
          />
        </MsalAuthenticationTemplate>
      </DailyWorkReportProvider>
    </>
  )
}

export default CreateDailyWorkReport
