import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { useAuth } from '../../context/AuthProvider'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Employee } from '../../types/Employee'
import { generateUniqueID } from '../../utils/common'
import { constants } from '../contants'


//TODO: Add validation to check if the employee has already been paid

interface EmployeeSelectionProps {   
  validateEmployeeSelection: boolean
}

const EmployeeSelection: FC<EmployeeSelectionProps> = ({ 
  validateEmployeeSelection,
}) => {
  const { selectedTenant } = useAuth()  
  const {
    selectedEmployees,
    setSelectedEmployees,
    journalDailyWorkDetails,
    setJournalDailyWorkDetails,
  } = useDailyWorkReport()
  const [employees, setEmployees] = useState<Employee[]>([])  
  const { showError } = useSnackbar()    

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()   

    const getEmployees = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getEmployees}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {         
          setEmployees(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los empleados')
        }
      }
    } 
   
    getEmployees()    

    return () => {
      isMounted = false
      controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id])

  const handleEmployeeSelection = (_event: any, newValue: Employee[]) => {   
    setSelectedEmployees(newValue)
   
    const updatedJournalDetails = journalDailyWorkDetails.filter(detail =>
      newValue.some(employee => employee.id === detail.employee.id)
    )  
    
    newValue.forEach(employee => {
      if (!updatedJournalDetails.some(detail => detail.employee.id === employee.id)) {
        updatedJournalDetails.push({
          id: generateUniqueID(),
          employee,
          employeeJobType: null,
          jobQuantity: 0,
          jobPrice: 0,
          paid: false,
        })
      }
    })
   
    setJournalDailyWorkDetails(updatedJournalDetails)
    }

  return (
    <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
      <Grid size={12}>
        <Autocomplete
          multiple
          id='combo-box-demo2'
          options={employees}
          getOptionLabel={(journalDailyWorkJobType) =>
            `${journalDailyWorkJobType.firstName} ${journalDailyWorkJobType.lastName}`
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions={true}
          value={selectedEmployees}
          onChange={handleEmployeeSelection}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Empleados'
              error={
                validateEmployeeSelection && selectedEmployees.length === 0
              }
              helperText={
                validateEmployeeSelection && selectedEmployees.length === 0
                  ? constants.requiredFieldMessage
                  : ''
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: null, // This removes the clear all action
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default EmployeeSelection
