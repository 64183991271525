import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

import Iconify from '../iconify'

SearchBar.propTypes = {
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    getOptionLabel: PropTypes.func.isRequired,
    isOptionEqualToValue: PropTypes.func,
    onChange: PropTypes.func, // <- Añade esto aquí
  }
  
  SearchBar.defaultProps = {
    placeholder: "Buscar...",
    isOptionEqualToValue: (option, value) => option.id === value.id,
    onChange: () => {},
  }

 export default function SearchBar({ options, placeholder, getOptionLabel, isOptionEqualToValue, onChange }) { // <- Añade onChange aquí
  return (
    <Autocomplete
      sx={{ width: '100%' }}
      autoHighlight
      popupIcon={null}
      slotProps={{
        paper: {
          sx: {
            width: '100%',
            [`& .${autocompleteClasses.option}`]: {
              typography: 'body2',
            },
          },
        },
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth 
          size='small'         
          placeholder={placeholder} 
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}