import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import TenantSelector from '../../components/TenantSelector'
import { useAuth } from '../../context/AuthProvider'
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Farm, FarmProject } from '../../types/Farm'
import { FarmJobType } from '../../types/Journal'
import { ProductInventory } from '../../types/Product'
import { constants } from '../contants'
import DailyWorkProducts from './DailyWorkProducts'

interface FarmInfoProps {
  validateFarmRequiredValues: boolean
}

const defaultProject = {
  id: -1,
  name: 'Sin Proyecto',
  description: '',
  startDate: '',
  endDate: '',
  farmId: 0,
  createdByUserEmail: '',
}

const FarmInfo: FC<FarmInfoProps> = ({ validateFarmRequiredValues }) => {
  const {
    selectedFarm,
    setSelectedFarm,
    selectedFarmProject,
    setSelectedFarmProject,
    selectedFarmJobTypes,
    setSelectedFarmJobTypes,
    selectedDate,
    setSelectedDate,
    observations,
    setObservations,
    selectedProducts,
    setSelectedProducts,
    journalDailyWorkProducts,
    setJournalDailyWorkProducts,
    isEditing,
    farmHasProjects,
    setFarmHasProjects,
  } = useDailyWorkReport()
  const { selectedTenant, isAllTenantsSelected } = useAuth()
  const [dateError, setDateError] = useState<DateValidationError | null>(null)
  const [isDateEmpty, setIsDateEmpty] = useState<boolean>(selectedDate === null)
  const [farms, setFarms] = useState<Farm[]>([])
  const [products, setProducts] = useState<ProductInventory[]>([])
  const { showError } = useSnackbar()
  const [farmJobTypes, setFarmJobTypes] = useState<FarmJobType[]>([])
  const minDate = dayjs().subtract(2, 'month')
  const maxDate = dayjs().add(2, 'month')
  const [farmProjects, setFarmProjects] = useState<FarmProject[]>([])

  useEffect(() => {
    if (isAllTenantsSelected) return

    let isMounted = true
    const controller = new AbortController()

    const getFarms = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getFarms}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {
          setFarms(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar las fincas')
        }
      }
    }

    const getProducts = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getProducts}${selectedTenant?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {
          setProducts(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los productos del inventario')
        }
      }
    }

    const getFarmJobTypes = async () => {
      try {
        const response = await axiosPrivate.get(endpoints.getFarmJobTypes, {
          signal: controller.signal,
        })
        if (isMounted) {
          setFarmJobTypes(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError(
            'Error al cargar los tipos de trabajo para el reporte diario'
          )
        }
      }
    }

    getFarms()
    getProducts()
    getFarmJobTypes()

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant])

  useEffect(() => {
    if (!selectedFarm) {
      setFarmProjects([])
      setFarmHasProjects(false)
      setSelectedFarmProject(null)
      return
    }

    let isMounted = true
    const controller = new AbortController()

    const getFarmActiveProjects = async () => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getFarmActiveProjects}${selectedFarm?.id}`,
          { signal: controller.signal }
        )
        if (isMounted) {         
          const projects = response.data as FarmProject[]
          setFarmProjects(projects.length > 0 ? [...projects, defaultProject] : [])

          if (
            selectedFarmProject &&
            !projects.some((project) => project.id === selectedFarmProject.id)
          ) {
            setSelectedFarmProject(null)
          }
          setFarmHasProjects(projects.length > 0)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los proyectos de la finca')
        }
      }
    }
    getFarmActiveProjects()

    return () => {
      isMounted = false
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFarm])

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue)
    if (!newValue) {
      setIsDateEmpty(true)
      setDateError(null)
    } else {
      setIsDateEmpty(false)
      setDateError(null)
    }
  }

  const dateErrorMessage = useMemo(() => {
    if (isDateEmpty && validateFarmRequiredValues)
      return constants.requiredFieldMessage

    switch (dateError) {
      case 'maxDate':
        return `La fecha no puede ser mayor a ${maxDate.format('DD-MM-YYYY')}`
      case 'minDate':
        return `La fecha no puede ser menor a ${minDate.format('DD-MM-YYYY')}`
      case 'invalidDate':
        return 'La fecha ingresada no es válida'
      default:
        return ''
    }
  }, [dateError, isDateEmpty, maxDate, minDate, validateFarmRequiredValues])

  return (
    <Grid container spacing={3} sx={{ mt: 1, mb: 1 }}>
      <Grid size={{ xs: 12, md: 6 }}>
        {isEditing ? (
          <TextField
            id='outlined-read-only-perfil'
            label='Perfil'
            value={selectedTenant?.name}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        ) : (
          <TenantSelector
            formControlSize='medium'
            includeAllTenantsOption={false}
          />
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <Autocomplete
          disablePortal
          id='combo-box-farm'
          options={farms}
          getOptionLabel={(farm) => farm.name}
          isOptionEqualToValue={(option: Farm, value: Farm) =>
            option.id === value.id
          }
          value={selectedFarm}
          onChange={(event, newValue) => {
            setSelectedFarm(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Finca'
              error={validateFarmRequiredValues && !selectedFarm}
              helperText={
                validateFarmRequiredValues && !selectedFarm
                  ? constants.requiredFieldMessage
                  : ''
              }
            />
          )}
        />
      </Grid>
      {farmHasProjects && (
        <Grid size={{ xs: 12, md: 6 }}>
          <Autocomplete
            disablePortal
            id='combo-box-farm-projects'
            options={farmProjects}
            getOptionLabel={(project) => project.name}
            isOptionEqualToValue={(option: FarmProject, value: FarmProject) =>
              option.id === value.id
            }
            value={selectedFarmProject}
            onChange={(event, newValue) => {
              setSelectedFarmProject(newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Proyecto'
                error={
                  validateFarmRequiredValues &&
                  farmHasProjects &&
                  !selectedFarmProject
                }
                helperText={
                  validateFarmRequiredValues &&
                  farmHasProjects &&
                  !selectedFarmProject
                    ? constants.requiredFieldMessage
                    : ''
                }
              />
            )}
          />
        </Grid>
      )}
      <Grid size={{ xs: 12, md: 6 }}>
        <DatePicker
          format='DD/MM/YYYY'
          label='Fecha'
          value={selectedDate}
          onChange={handleDateChange}
          sx={{ width: '100%' }}
          onError={(newError) => setDateError(newError)}
          slotProps={{
            textField: {
              error:
                validateFarmRequiredValues &&
                (isDateEmpty || dateError != null),
              helperText: dateErrorMessage,
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>
      <Grid size={12}>
        <Autocomplete
          multiple
          id='combo-box-demo2'
          options={farmJobTypes}
          getOptionLabel={(journalDailyWorkJobType) =>
            journalDailyWorkJobType.name
          }
          isOptionEqualToValue={(option: FarmJobType, value: FarmJobType) =>
            option.id === value.id
          }
          filterSelectedOptions={true}
          value={selectedFarmJobTypes}
          onChange={(event, newValue) => {
            setSelectedFarmJobTypes(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Trabajos realizados'
              error={
                validateFarmRequiredValues && selectedFarmJobTypes.length === 0
              }
              helperText={
                validateFarmRequiredValues && selectedFarmJobTypes.length === 0
                  ? constants.requiredFieldMessage
                  : ''
              }
            />
          )}
        />
      </Grid>
      <DailyWorkProducts
        products={products}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        journalDailyWorkProducts={journalDailyWorkProducts}
        setJournalDailyWorkProducts={setJournalDailyWorkProducts}
        validateProductsRequiredValues={validateFarmRequiredValues}
      />
      <Grid size={12}>
        <TextField
          sx={{ width: '100%' }}
          id='outlined-multiline-flexible'
          label='Observaciones'
          multiline
          maxRows={4}
          value={observations}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setObservations(event.target.value)
          }
        />
      </Grid>
    </Grid>
  )
}

export default FarmInfo
