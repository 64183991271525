import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from "react"

export interface Tenant {
    name: string
    id: number
}

export interface User {
    userid: number
    username: string
    email: string
    imageurl?: string
    isApprover: boolean
    isAdmin: boolean
}

export interface DecodedToken {
    userid: number
    username: string
    email: string
    tenants: string
    imageurl?: string
}

export interface AuthState {
    user?: User    
    tenants?: Tenant[]
    // roles?: string[] //TODO: Implement roles
}

interface AuthContextState {
    auth: AuthState
    setAuth: Dispatch<SetStateAction<AuthState>>
    selectedTenant: Tenant | null
    setSelectedTenant: Dispatch<SetStateAction<Tenant | null>>
    isAllTenantsSelected: boolean
    setIsAllTenantsSelected: Dispatch<SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextState | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [auth, setAuth] = useState<AuthState>({})
    const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
    const [isAllTenantsSelected, setIsAllTenantsSelected] = useState<boolean>(false)

    return (
        <AuthContext.Provider value={{ auth, setAuth, selectedTenant, setSelectedTenant , isAllTenantsSelected, setIsAllTenantsSelected }}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = (): AuthContextState => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider")
    }

    return context
}

export { AuthProvider, useAuth }

