import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import { Tenant, useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { EmployeeJobTypeDetails } from '../../types/Journal'
import { constants } from '../contants'
import ConfigurationSkeletons from './ConfigurationSkeletons'

type EditEmployeeJobTypeRequest = {
  id: number
  updatedByUserEmail: string
  name: string
  jobPrice: number
  tenantIds: number[]
}

const EmployeeJobTypeEditView: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { auth } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [jobPrice, setJobPrice] = useState<string>('')
  const [nameError, setNameError] = useState<boolean>(false)
  const [jobPriceError, setJobPriceError] = useState<boolean>(false)
  const [employeeJobType, setEmployeeJobType] = useState<EmployeeJobTypeDetails | null>(null)
  const { showError, showSuccess } = useSnackbar()
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [selectedTenants, setSelectedTenants] = useState<number[]>([])

  const handleToggleTenant = (tenantId: number) => {
    setSelectedTenants((prevSelected) =>
      prevSelected.includes(tenantId)
        ? prevSelected.filter((id) => id !== tenantId)
        : [...prevSelected, tenantId]
    )
  }

  const getEmployeeJobTypeDetails = async (controller: AbortController) => {
    try {
      const url = endpoints.getEmployeeJobType
      .replace('{employeeJobTypeId}', id || '')
      .replace('{includeJobPriceHistory}', false.toString())

      const response = await axiosPrivate.get(
        url,
        { signal: controller.signal }
      )

      setEmployeeJobType(response.data)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al obtener los detalles del tipo de trabajo')
      }
    }
  }

  const getTenants = async (controller: AbortController) => {
    try {
      const response = await axiosPrivate.get(endpoints.getTenants, {
        signal: controller.signal,
      })
      setTenants(response.data)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al cargar los perfiles')
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const fetchData = async () => {
      if (isMounted) {
        await getEmployeeJobTypeDetails(controller)
        await getTenants(controller)
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (employeeJobType) {
      setName(employeeJobType.name)
      setJobPrice(employeeJobType.jobPrice ? employeeJobType.jobPrice.toString() : '')
      setSelectedTenants(employeeJobType.tenants.map((t) => t.id))
    }
  }, [employeeJobType])

  const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!name.trim()) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    if (!jobPrice || Number(jobPrice) === 0) {
      setJobPriceError(true)
      isValid = false
    } else {
      setJobPriceError(false)
    }

    return isValid
  }

  const EditEmployeeJobType = async (jobType: EditEmployeeJobTypeRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendEditEmployeeJobTypeRequest = async () => {
      try {
        const response = await axiosPrivate.put(
          endpoints.editEmployeeJobType,
          jobType,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess('Trabajo actualizado correctamente!')
          navigate('/trabajos-empleados')
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error actualizar el trabajo ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendEditEmployeeJobTypeRequest()
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!validateRequiredFields()) {
      showError('Por favor, complete los campos requeridos.')
      return
    }

    const jobType: EditEmployeeJobTypeRequest = {
      id: Number(id),
      name,
      updatedByUserEmail: auth.user?.email || '', //TODO: Validate user
      jobPrice: Number(jobPrice) || 0,
      tenantIds: selectedTenants,
    }

    EditEmployeeJobType(jobType)
  }

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1)
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Editar Trabajo</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Box
        component='form'
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            {isLoading ? (
              <ConfigurationSkeletons />
            ) : (
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    label='Descripción'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    error={nameError}
                    helperText={nameError ? constants.requiredFieldMessage : ''}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <TextField
                    fullWidth
                    label='Precio'
                    name='jobPrice'
                    type='text'
                    onChange={(e) => {
                      const value = e.target.value
                      if (/^-?\d*$/.test(value)) { // Allow only numbers and negative sign
                        setJobPrice(value)
                      }
                    }}
                    required
                    value={jobPrice}
                    error={jobPriceError}
                    helperText={
                      jobPriceError ? constants.requiredFieldNoZeroMessage : ''
                    }
                  />
                </Grid>
                <Grid size={12} container>
                  <Divider style={{ width: '100%' }} />
                </Grid>
                <Grid size={12}>
                  <Card>
                    <CardHeader
                      title='Perfiles asociados al trabajo'
                      subheader={`${selectedTenants.length}/${tenants.length} seleccionados`}
                    />
                    <Divider />
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {tenants.map((tenant) => {
                        const isSelected = selectedTenants.includes(tenant.id)
                        return (
                          <ListItemButton
                            key={tenant.id}
                            onClick={() => handleToggleTenant(tenant.id)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge='start'
                                checked={isSelected}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText primary={tenant.name} />
                          </ListItemButton>
                        )
                      })}
                    </List>
                  </Card>
                </Grid>
              </Grid>
            )}
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}>
            <Button type='submit' variant='contained' color='inherit'>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}

export default EmployeeJobTypeEditView
