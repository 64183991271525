import { Box, Button, Card, CardActions, CardContent, Container, Divider, Stack, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { EmployeeJobType } from '../../types/Journal'
import { constants } from '../contants'

type CreateEmployeeJobTypeRequest = {
    name: string
    jobPrice: number
    createdByUserEmail: string
}

const EmployeeJobTypeCreateView: FC = () => {
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const [jobPrice, setJobPrice] = useState<string>('')
  const { auth } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showSuccess, showError } = useSnackbar()
  const [nameError, setNameError] = useState<boolean>(false)
  const [jobPriceError, setJobPriceError] = useState<boolean>(false)

   const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!name.trim()) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    if (!jobPrice || Number(jobPrice) === 0) {
      setJobPriceError(true)
      isValid = false
    } else {
      setJobPriceError(false)
    }

    return isValid
  }

  const CreateJobType = async (newJobType: CreateEmployeeJobTypeRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendCreateEmployeeJobTypeRequest = async () => {    
        try {
          const response = await axiosPrivate.post(
            endpoints.createEmployeeJobType,
            newJobType,
            { signal: controller.signal }
          )         
    
          if (response.status === 200) {
            showSuccess('Trabajo agregado correctamente!')            
            const createdJobType = response.data as EmployeeJobType    
            navigate(`/trabajos-empleados/editar/${createdJobType.id}`, { state: { id: createdJobType.id } })
    
          }
        } catch (err: any) {
          const error = err as AxiosError<ApiError>
    
          if (error.name !== 'CanceledError') {
            showError('Error crear el trabajo ' + error?.response?.data?.detail)
          }
        } finally {
          setIsLoading(false)
        }
      }

      SendCreateEmployeeJobTypeRequest()
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()   

    if (!validateRequiredFields()) {
        showError('Por favor, complete los campos requeridos.')
        return
      }

    const newEmployeeJobType: CreateEmployeeJobTypeRequest = {      
      name,
      jobPrice: Number(jobPrice),
      createdByUserEmail: auth.user?.email || '', //TODO: Validate user
    }
   
    CreateJobType(newEmployeeJobType)
  }  

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate('/trabajos-fincas')
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Agregar trabajo para empleados</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Box
        component='form'
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {isLoading && <Loading centered={true} />}
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label='Descripción del Trabajo'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  error={nameError}
                  helperText={nameError ? constants.requiredFieldMessage : ''}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label='Precio'
                  name='jobPrice'
                  type='text'
                  onChange={(e) => {
                    const value = e.target.value
                    if (/^-?\d*$/.test(value)) {
                      // Allow only numbers and negative sign
                      setJobPrice(value)
                    }
                  }}
                  required
                  value={jobPrice}
                  error={jobPriceError}
                  helperText={
                    jobPriceError ? constants.requiredFieldNoZeroMessage : ''
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button type='submit' variant='contained' color='inherit'>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}

export default EmployeeJobTypeCreateView
