import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import DailyWorkReportDetailsView from '../../sections/DailyWorkReport/DailyWorkReportDetailsView'

const DailyWorkReportDetails = () => {
  const location = useLocation();
  const { currentJournalDailyWorkId } = location.state || {}  

  return (
    <>
      <Helmet>
        <title>Reporte de Trabajo | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <DailyWorkReportDetailsView journalDailyWorkId={currentJournalDailyWorkId} />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default DailyWorkReportDetails
