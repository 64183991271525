import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import EditProductInventoryView from '../../sections/ProductInventory/EditProductInventoryView'

const EditProductInventory = () => {
  const location = useLocation();
  const { currentProduct } = location.state || {}
  
  return (
    <>
      <Helmet>
        <title>Actualizar Producto | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <EditProductInventoryView currentProduct={currentProduct} />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default EditProductInventory
