import { Box, Button, Card, CardActions, CardContent, Container, Divider, Stack, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { AxiosError } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { Farm, FarmProject } from '../../types/Farm'
import { constants } from '../contants'

type CreateFarmProjectRequest = {
    name: string
    description: string
    startDate: string
    farmId: number
    createdByUserEmail: string
}

const FarmProjectCreateView: FC = () => {
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const { auth } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showSuccess, showError } = useSnackbar()
  const [nameError, setNameError] = useState<boolean>(false)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)  
  const { farmId } = useParams<{ farmId: string }>()
  const [farm, setFarm] = useState<Farm | null>(null)
  const [dateError, setDateError] = useState<DateValidationError | null>(null)
  const minDate = dayjs().subtract(1, 'year')
  const maxDate = dayjs().add(2, 'month')  

    useEffect(() => {
      const controller = new AbortController()
      setIsLoading(true)
    
      const getFarmDetails = async () => {
        try {
          const response = await axiosPrivate.get(`${endpoints.getFarm}${farmId}`, {
            signal: controller.signal,
          })
          setFarm(response.data)
        } catch (err: any) {
          const error = err as AxiosError
          if (error.name !== 'CanceledError') {
            showError('Error al obtener la finca')
          }
        } finally {
          setIsLoading(false)
        }
      }
    
      getFarmDetails()
    
      return () => {
        controller.abort()
        setIsLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

   const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!name.trim()) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    if (!description.trim()) {
      setDescriptionError(true)
      isValid = false
    } else {
      setDescriptionError(false)
    }

    if (!startDate) {
      setDateError('invalidDate')
      isValid = false
    }

    if (!farm) {
      showError('La finca no es válida.')
      return false
    }  

    return isValid
  }

  const dateErrorMessage = useMemo(() => {
    switch (dateError) {
      case 'maxDate':
        return `La fecha no puede ser mayor a ${maxDate.format('DD-MM-YYYY')}`
      case 'minDate':
        return `La fecha no puede ser menor a ${minDate.format('DD-MM-YYYY')}`
      case 'invalidDate':
        return constants.requiredFieldMessage      
      default:
        return ''
    }
  }, [dateError, maxDate, minDate])

    
  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue)
    if (newValue) {
      setDateError(null)
    }
  }

  const CreateFarmProject = async (newFarmProject: CreateFarmProjectRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendCreateFarmProjectRequest = async () => {    
        try {
          const response = await axiosPrivate.post(
            endpoints.createFarmProject,
            newFarmProject,
            { signal: controller.signal }
          )         
    
          if (response.status === 200) {
            showSuccess('Proyecto creado correctamente!')
            const createdFarmProject = response.data as FarmProject            
            navigate(`/fincas/proyecto-detalles/${createdFarmProject.id}`, { state: { id: createdFarmProject.id } })
    
          }
        } catch (err: any) {
          const error = err as AxiosError<ApiError>
    
          if (error.name !== 'CanceledError') {
            showError('Error crear el proyecto ' + error?.response?.data?.detail)
          }
        } finally {
          setIsLoading(false)
        }
      }

      SendCreateFarmProjectRequest()
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()   

    if (!validateRequiredFields()) {
        showError('Por favor, complete los campos requeridos.')
        return
      }

    const newProject: CreateFarmProjectRequest = {      
      name,
      description,
      startDate: startDate?.format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'), //Get today date if startDate is null
      farmId: Number(farmId),
      createdByUserEmail: auth.user?.email || '', //TODO: Validate user
    }
   
    CreateFarmProject(newProject)
  }  

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1)
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>
              Crear proyecto para la finca {farm?.name}
            </Typography>
          </Grid>
        </Grid>
      </Stack>

      <Box
        component='form'
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {isLoading && <Loading centered={true} />}
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  fullWidth
                  label='Nombre del proyecto'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  error={nameError}
                  helperText={nameError ? constants.requiredFieldMessage : ''}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <DatePicker
                  format='DD/MM/YYYY'
                  label='Fecha de inicio'
                  value={startDate}
                  onChange={handleDateChange}
                  sx={{ width: '100%' }}
                  onError={(newError) => setDateError(newError)}
                  slotProps={{
                    textField: {
                      error: dateError != null,
                      helperText: dateErrorMessage,
                    },
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  label='Descripción del proyecto'
                  multiline
                  maxRows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  error={descriptionError}
                  helperText={descriptionError ? constants.requiredFieldMessage : ''}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button type='submit' variant='contained' color='inherit'>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}

export default FarmProjectCreateView
