import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import DialogContentText from '@mui/material/DialogContentText'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import TenantSelector from '../../components/TenantSelector'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { ProductInventory } from '../../types/Product'
import { fNumber } from '../../utils/format-number'
import ProductSkeletons from './ProductSkeletons'

const ProductInventoryView: FC = () => {
    const [products, setProducts] = useState<ProductInventory[]>([])    
    const { auth, selectedTenant, isAllTenantsSelected } = useAuth()    
    const { showSuccess, showError } = useSnackbar()    
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [deletingProductId, setDeletingProductId] = useState<number | null>(null)

    useEffect(() => {
        let isMounted = true
        const controller = new AbortController()
    
        if (isMounted) {
          getProducts()
        }
    
        return () => {
          isMounted = false
          controller.abort()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTenant?.id])

        const getProducts = async () => {
            try {
              setIsLoading(true)
              const response = await axiosPrivate.get(
                `${endpoints.getProducts}${selectedTenant?.id}`
              )
              setProducts(response.data)
              setIsLoading(false)
            } catch (err: any) {
              const error = err as AxiosError
              if (error.name !== 'CanceledError') {
                setIsLoading(false)
                showError('Error al cargar los productos del inventario')
              }
            }
        }

        const handleEditRow = (id: GridRowId) => () => {
            const productToEdit = products.find(product => product.id === id)
            navigate(`/inventario-productos/editar/${id}`, { state: { currentProduct: productToEdit } })
        }

        const handleDeleteRow = (id: GridRowId) => () => {           
            setDeletingProductId(Number(id))
            setOpenDialog(true)      
        }

        const handleOnCloseDelete = () => {
            setOpenDialog(false)
        }
        
        const handleOnConfirmDelete = async () => {
            if (deletingProductId !== null) {
                await DeleteProduct(Number(deletingProductId))
                setOpenDialog(false)
            }
        }

        const dialogAcceptButton = {
          text: 'Eliminar',
          action: handleOnConfirmDelete,
        }
      
        const dialogCancelButton = {
          text: 'Cancelar',
          action: handleOnCloseDelete,
        }

        const DeleteProduct = async (productId: number) => {
            setIsLoading(true)
            const controller = new AbortController()
        
            const SendDeleteProductRequest = async () => {    
                try {
                    const url = endpoints.deleteProduct
                    .replace('{productId}', productId.toString())
                    .replace('{deletedByUserEmail}', auth.user?.email || '')

                  const response = await axiosPrivate.delete(url,{ signal: controller.signal })         
            
                  if (response.status === 200) {
                    showSuccess('Producto eliminado correctamente!')            
                    navigate('/inventario-productos')            
                  }
                } catch (err: any) {
                  const error = err as AxiosError<ApiError>
            
                  if (error.name !== 'CanceledError') {
                    showError('Error eliminar el producto ' + error?.response?.data?.detail)
                  }
                } finally {
                  setIsLoading(false)
                  getProducts()                 
                }
              }
        
              SendDeleteProductRequest()
          }

        const columns: GridColDef<ProductInventory>[] = [
          {
            headerName: 'Producto',
            field: 'product',
            valueGetter: (value, row) => row.name,
            minWidth: 140,
            flex: 1,
            renderCell: (
              params // Text wrapping
            ) => (
              <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
                {params.value}
              </div>
            ),
          },
          {
            headerName: 'Cantidad',
            field: 'quantity',
            minWidth: 128,
            flex: 1,
            valueGetter: (value, row)  => fNumber(value),
          },
          {
            headerName: 'Medida',
            field: 'productMeasure',
            valueGetter: (value, row) => row.productMeasure?.name,
            minWidth: 140,
            flex: 1,
            renderCell: (
              params // Text wrapping
            ) => (
              <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
                {params.value}
              </div>
            ),            
          },
          {
            headerName: 'Precio',
            field: 'unitPrice',
            minWidth: 128,
            flex: 1,
            valueGetter: (value, row) => fNumber(value),
          },
          {
            headerName: 'Descripción',
            field: 'description',
            valueGetter: (value, row) => row.description,
            minWidth: 150,
            flex: 1,
            renderCell: (
              params // Text wrapping
            ) => (
              <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
                {params.value}
              </div>
            ),           
          },
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 55,
            getActions: (params: GridRowParams<ProductInventory>) => [
              <GridActionsCellItem
                sx={{ p: 0, m: 0 }}
                icon={<EditIcon />}
                label='Editar'
                className='textPrimary'
                onClick={handleEditRow(params.row.id)}
                color='inherit'
              />,
              <GridActionsCellItem
                sx={{ p: 0, m: 0 }}
                icon={<DeleteIcon />}
                label='Eliminar'
                onClick={handleDeleteRow(params.row.id)}
                color='inherit'
              />,
            ],
          },
        ]        

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>
              {isAllTenantsSelected
                ? 'Inventario de productos en todos los perfiles'
                : `Inventario de productos ${selectedTenant?.name}`}
            </Typography>
          </Grid>

          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              component={NavLink}
              to='/inventario-productos/agregar'
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Agregar Producto
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <Grid container direction='column'>
        <Grid size={{ xs: 12, md: 6 }} sx={{ mb: 1 }}>
          <TenantSelector includeAllTenantsOption={false} />
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {isLoading ? (
              <ProductSkeletons count={5} />
            ) : (
              <Grid size={12}>
                <DataGrid
                  rows={products}
                  columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  density='compact'
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 300,
                        placeholder: 'Buscar',
                      },
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <DynamicDialog
        openDialog={openDialog}
        dialogTitle='Confirmar eliminación'
        onCloseAction={handleOnCloseDelete}
        buttonAccept={dialogAcceptButton}
        buttonCancel={dialogCancelButton}
      >
        <DialogContentText id='generic-dialog-description'>
          ¿Realmente quiere eliminar este producto?
        </DialogContentText>
      </DynamicDialog>
    </Container>
  )
}

export default ProductInventoryView
  