import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmProjectDetailsView from '../../sections/Farm/FarmProjectDetailsView'

const FarmProjectDetails = () => {
  return (
    <>
      <Helmet>
        <title>Proyecto | Sistema Planilla</title>
      </Helmet>
      
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <FarmProjectDetailsView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default FarmProjectDetails
