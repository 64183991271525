import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmView from '../../sections/Farm/FarmView'

const Farm = () => {
  return (
    <>
      <Helmet>
        <title>Fincas | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <FarmView />
      </MsalAuthenticationTemplate>      
    </>
  )
}

export default Farm
