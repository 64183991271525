import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import React from 'react'

type ConfigurationSkeletonsProps = {
  count?: number
}

const ConfigurationSkeletons: React.FC<ConfigurationSkeletonsProps> = ({ count = 5 }) => {
  return (
    <>
      {Array.from(new Array(count)).map((_, rowIndex) => (
        <Grid container key={rowIndex} alignItems="center" style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', padding: '10px 0' }}>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid size={{ xs: 6, sm: 2 }}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Skeleton variant="text" width="100%" />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default ConfigurationSkeletons