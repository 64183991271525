import VisibilityIcon from '@mui/icons-material/Visibility'
import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import TenantSelector from '../../components/TenantSelector'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import {
  FarmDetails,
  FarmStatus,
} from '../../types/Farm'
import { fDateShort } from '../../utils/format-time'
import FarmFilters from './FarmFilters'
import FarmSkeletons from './FarmSkeletons'

type GetFarmsDetailsRequest = {
  tenantId: number | null
  FarmStatus: FarmStatus  
}

const FarmView: FC = () => {
  const [Farms, setFarms] = useState<FarmDetails[]>([])  
  const { showError } = useSnackbar()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedFarmStatus, setSelectedFarmStatus] = useState<FarmStatus>(FarmStatus.Active)    
  const { selectedTenant, isAllTenantsSelected } = useAuth()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    if (isMounted) {
      getFarms()
    }

    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id, selectedFarmStatus])

  const getFarms = async () => {
    try {
      const getFarmsDetailsRequest: GetFarmsDetailsRequest = {
        tenantId: isAllTenantsSelected || !selectedTenant ? null : selectedTenant.id,
        FarmStatus: selectedFarmStatus,        
      }

      setIsLoading(true)
      const response = await axiosPrivate.post(
        endpoints.getFarmsDetails,
        getFarmsDetailsRequest
      )
      setFarms(response.data)
      setIsLoading(false)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        setIsLoading(false)
        showError('Error al cargar las fincas')
      }
    }
  }

  const handleViewRow = (id: GridRowId) => () => {
    navigate(`/fincas/detalles/${id}`, { state: { id: id } })   
  }

  const columns: GridColDef<FarmDetails>[] = [
    {
      headerName: 'Finca',
      field: 'name',
      valueGetter: (value, row) => row.name,
      minWidth: 158,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      headerName: 'Perfil',
      field: 'tenant',
      valueGetter: (value, row) => row.tenant.name,
      minWidth: 160,
      flex: 1,
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      headerName: 'Ultimo reporte',
      field: 'lastJournalReportDate',
      valueGetter: (value, row) => fDateShort(row.lastJournalReportDate),
      minWidth: 165,
      flex: 1,
    },
    {
      headerName: 'Estado',
      field: 'isDeleted',
      valueGetter: (value, row) => (row.isDeleted ? 'Desactivado' : 'Activo'),
      minWidth: 125,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === 'Desactivado' ? '#B71D18' : '#118D57',
            backgroundColor:
              params.value === 'Desactivado'
                ? 'rgba(255, 86, 48, 0.16)'
                : 'rgba(34, 197, 94, 0.16)',
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',

      getActions: (params: GridRowParams<FarmDetails>) => [
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<VisibilityIcon />}
          label='Ver'
          className='textPrimary'
          onClick={handleViewRow(params.row.id)}
          color='inherit'
        />,
      ],
    },
  ]

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>
              {isAllTenantsSelected
                ? 'Fincas en todos los perfiles'
                : `Fincas ${selectedTenant?.name}`}
            </Typography>
          </Grid>

          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              component={NavLink}
              to='/fincas/agregar'
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:plus-fill' />}
            >
              Agregar Finca
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <Grid container direction='column'>
        <Grid size={{ xs: 12, md: 6 }} sx={{ mb: 1 }}>
          <TenantSelector />
        </Grid>
        <FarmFilters
          selectedFarmStatus={selectedFarmStatus}
          onFarmStatusChange={setSelectedFarmStatus}         
        />
      </Grid>

      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {isLoading ? (
              <FarmSkeletons count={5} />
            ) : (
              <Grid size={12}>
                <DataGrid
                  rows={Farms}
                  columns={columns}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableRowSelectionOnClick
                  density='compact'
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 300,
                        placeholder: 'Buscar',
                      },
                      printOptions: { disableToolbarButton: true },
                      csvOptions: { disableToolbarButton: true },
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default FarmView
