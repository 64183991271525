import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import {
  Avatar,
  Box,
  CardHeader,
  Checkbox,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowParams,
} from '@mui/x-data-grid'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { AxiosError } from 'axios'
import Compressor from 'compressorjs'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { Tenant, useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import {
  EmployeeDetails,
  EmployeeGender,
  EmployeePaymentType,
  getGenderDescription,
} from '../../types/Employee'
import { constants } from '../contants'
import EditEmployeePaymentTypeDialog from './EditEmployeePaymentTypeDialog'

type EmployeePaymentTypeRequest = {
  id: number
  employeeId: number
  paymentTypeId: number
  isDefault: boolean
  employeeBankAccount?: EmployeeBankAccountRequest
}

type EmployeeBankAccountRequest = {
  id: number
  bankId: number
  accountNumber?: string
  ownerName?: string
  employeePaymentTypeId: number
}

type EditEmployeeRequest = {
  id: number
  updatedByUserEmail: string
  idDocument: string
  birthDate?: string
  firstName: string
  lastName: string
  address: string
  gender: string
  tenantIds: number[]
  employeePaymentTypes: EmployeePaymentTypeRequest[]
}

const EmployeeEditView: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { showError, showSuccess, showWarning } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [employee, setEmployee] = useState<EmployeeDetails | null>(null)
  const [idDocument, setIdDocument] = useState<string>('')
  const [birthDate, setBirthDate] = useState<Dayjs | null>(null)
  const [dateError, setDateError] = useState<DateValidationError | null>(null)
  const [isDateEmpty, setIsDateEmpty] = useState<boolean>(birthDate === null)
  const minDate = dayjs().subtract(90, 'year')
  const maxDate = dayjs().subtract(5, 'year')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [selectedTenants, setSelectedTenants] = useState<number[]>([])
  const [accountNumberMaxLength, setAccountNumberMaxLength] = useState<number>(0)
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
  const [selectedImageBytes, setSelectedImageBytes] = useState<File | null>(null)
  const [employeePaymentTypes, setEmployeePaymentTypes] = useState<EmployeePaymentType[]>([])
  const [editMode, setEditMode] = useState(false)
  const [selectedPaymentType, setSelectedPaymentType] = useState<EmployeePaymentType | null>(null)
  const [openAddPaymentTypeDialog, setOpenAddPaymentTypeDialog] = useState(false)
  const [idDocumentError, setIdDocumentError] = useState<boolean>(false)
  const [firstNameError, setFirstNameError] = useState<boolean>(false)
  const [lastNameError, setLastNameError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<boolean>(false)

  const handleToggleTenant = (tenantId: number) => {
    setSelectedTenants(
      (prevSelected) =>
        prevSelected.includes(tenantId)
          ? prevSelected.filter((id) => id !== tenantId)
          : [...prevSelected, tenantId]
    )
  }

  const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!idDocument.trim()) {
      setIdDocumentError(true)
      isValid = false
    } else {
      setIdDocumentError(false)
    }

    if (!firstName.trim()) {
      setFirstNameError(true)
      isValid = false
    } else {
      setFirstNameError(false)
    }

    if (!lastName.trim()) {
      setLastNameError(true)
      isValid = false
    } else {
      setLastNameError(false)
    }

    if (!address.trim()) {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }

    return isValid
  }

  const validateEmployeePayments = () => {
    const defaultPaymentTypes = employeePaymentTypes.filter(
      (pt) => pt.isDefault
    )

    if (defaultPaymentTypes.length > 1) {
      showError(
        'No puede haber más de un método de pago predeterminado. Por favor, edite los métodos de pago y seleccione solo uno como predeterminado en uso.'
      )
      return false
    }

    if (employeePaymentTypes.length > 0 && defaultPaymentTypes.length === 0) {
      showError(
        'Debe haber al menos un método de pago predeterminado. Por favor, edite los métodos de pago y seleccione uno como predeterminado en uso.'
      )
      return false
    }

    return true
  }

  const handleAddPaymentType = (newPaymentType: EmployeePaymentType) => {
    setEmployeePaymentTypes((prev) => [...prev, newPaymentType])
  }

  const handleEditPaymentType = (updatedPaymentType: EmployeePaymentType) => {
    setEmployeePaymentTypes((prev) =>
      prev.map((paymentType) =>
        paymentType.id === updatedPaymentType.id
          ? updatedPaymentType
          : paymentType
      )
    )
  }

  const handleEditRow = (paymentType: EmployeePaymentType) => {
    setSelectedPaymentType(paymentType)
    setEditMode(true)
    setOpenAddPaymentTypeDialog(true)
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        // 1MB
        new Compressor(file, {
          quality: 0.4, // Adjust the quality of the compressed image
          maxWidth: 800, // Resize image if it exceeds 1024px
          success(result: Blob) {
            const compressedFile = new File([result], file.name, {
              type: file.type,
            })
            setSelectedImageBytes(compressedFile)
            setSelectedImageUrl(URL.createObjectURL(compressedFile))
          },
          error(err) {
            console.error('Error al comprimir la imagen:', err)
          },
        })
      } else {
        setSelectedImageBytes(file)
        setSelectedImageUrl(URL.createObjectURL(file))
      }
    }
  }

  const handleDeletePaymentType = (id: GridRowId) => {
    setEmployeePaymentTypes((prev) => {
      const updatedPaymentTypes = prev.filter(
        (paymentType) => paymentType.id !== id
      )
      const hasDefault = updatedPaymentTypes.some(
        (paymentType) => paymentType.isDefault
      )

      if (!hasDefault && updatedPaymentTypes.length > 0) {
        updatedPaymentTypes[0].isDefault = true
        showWarning(
          'Atención: Dado que se eliminó el método de pago en uso, se ha seleccionado uno por defecto'
        )
      }

      return updatedPaymentTypes
    })
  }
  const getEmployeeDetails = async (
    controller: AbortController
  ): Promise<EmployeeDetails | undefined> => {
    try {
      const response = await axiosPrivate.get(`${endpoints.getEmployee}${id}`, {
        signal: controller.signal,
      })
      setEmployee(response.data)
      return response.data as EmployeeDetails
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al obtener los detalles del empleado')
      }
      return undefined
    }
  }

  const getTenants = async (controller: AbortController) => {
    try {
      const response = await axiosPrivate.get(endpoints.getTenants, {
        signal: controller.signal,
      })
      setTenants(response.data)
    } catch (err: any) {
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al cargar los perfiles')
      }
    }
  }

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const fetchData = async () => {
      if (isMounted) {
        const employeeDetails = await getEmployeeDetails(controller)
        if (isMounted && employeeDetails) {
          await getTenants(controller)
        }
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (employee) {
      setIdDocument(employee.idDocument)
      setBirthDate(dayjs(employee.birthDate))
      setIsDateEmpty(employee.birthDate === null || employee.birthDate === '')
      setFirstName(employee.firstName)
      setLastName(employee.lastName)
      setGender(employee.gender)
      setAddress(employee.address)
      setEmployeePaymentTypes(employee.employeePaymentTypes)
      setSelectedTenants(employee.tenants.map((t) => t.id))
      const maxLength =
        employee.employeePaymentTypes.length > 0
          ? employee.employeePaymentTypes.reduce((max, type) => {
              const accountNumberLength =
                type.employeeBankAccount?.accountNumber?.length || 0
              return Math.max(max, accountNumberLength)
            }, 0)
          : 12
      setAccountNumberMaxLength(Math.max(maxLength, 12))
    }
  }, [employee])

  const handleDateChange = (newValue: Dayjs | null) => {
    setBirthDate(newValue)
    if (!newValue) {
      setIsDateEmpty(true)
      setDateError(null)
    } else {
      setIsDateEmpty(false)
      setDateError(null)
    }
  }

  const dateErrorMessage = useMemo(() => {
    if (isDateEmpty) return constants.requiredFieldMessage

    switch (dateError) {
      case 'maxDate':
        return `La fecha no puede ser mayor a ${maxDate.format('DD-MM-YYYY')}`
      case 'minDate':
        return `La fecha no puede ser menor a ${minDate.format('DD-MM-YYYY')}`
      case 'invalidDate':
        return 'La fecha ingresada no es válida'
      default:
        return ''
    }
  }, [dateError, isDateEmpty, maxDate, minDate])

  const EditEmployee = async (employeeDetails: EditEmployeeRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const formData = new FormData()
    formData.append('Id', employeeDetails.id.toString())
    formData.append('UpdatedByUserEmail', employeeDetails.updatedByUserEmail)
    formData.append('IdDocument', employeeDetails.idDocument)
    if (employeeDetails.birthDate)
      formData.append('BirthDate', employeeDetails.birthDate)
    formData.append('FirstName', employeeDetails.firstName)
    formData.append('LastName', employeeDetails.lastName)
    formData.append('Address', employeeDetails.address)
    formData.append('Gender', employeeDetails.gender)
    formData.append('TenantIdsJson', JSON.stringify(employeeDetails.tenantIds))
    formData.append(
      'EmployeePaymentTypesJson',
      JSON.stringify(employeeDetails.employeePaymentTypes)
    )

    if (selectedImageBytes) {
      formData.append('UpdatedImage', selectedImageBytes)
    }

    try {
      const response = await axiosPrivate.put(
        endpoints.editEmployee,
        formData,
        {
          signal: controller.signal,
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      if (response.status === 200) {
        showSuccess('Empleado actualizado correctamente!')
        navigate(`/empleados/detalles/${id}`, { state: { id: id } })
      }
    } catch (err: any) {
      const error = err as AxiosError<ApiError>
      if (error.name !== 'CanceledError') {
        showError(
          'Error al actualizar el empleado: ' + error?.response?.data?.detail
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!validateRequiredFields()) {
      showError('Por favor, complete los campos requeridos.')
      return
    }

    if (!validateEmployeePayments()) {
      return
    }

    if (employee?.id === undefined) {
      showError('No se puede editar el empleado porque el ID es inválido')
      return
    }

    const employeeDetails: EditEmployeeRequest = {
      id: employee?.id,
      updatedByUserEmail: auth.user?.email || '',
      idDocument,
      birthDate: birthDate?.format('YYYY-MM-DD') || '',
      firstName,
      lastName,
      address,
      gender,
      tenantIds: selectedTenants,
      employeePaymentTypes: employeePaymentTypes.map((paymentType) => ({
        id: paymentType.id < 0 ? 0 : paymentType.id,
        employeeId: employee.id,
        paymentTypeId: paymentType.paymentTypeId,
        isDefault: paymentType.isDefault,
        employeeBankAccount: paymentType.employeeBankAccount
          ? {
              id:
                paymentType.employeeBankAccount.id < 0
                  ? 0
                  : paymentType.employeeBankAccount.id,
              bankId: paymentType.employeeBankAccount.bankId,
              accountNumber: paymentType.employeeBankAccount.accountNumber,
              ownerName: paymentType.employeeBankAccount.ownerName,
              employeePaymentTypeId: paymentType.id < 0 ? 0 : paymentType.id,
            }
          : undefined,
      })),
    }

    EditEmployee(employeeDetails)
  }

  const columns: GridColDef<EmployeePaymentType>[] = [
    {
      headerName: 'Tipo',
      field: 'paymentTypeDescription',
      minWidth: 125,
      flex: 1,
    },
    {
      headerName: 'En uso',
      field: 'isDeleted',
      valueGetter: (value, row) => (row.isDefault ? 'Si' : 'No'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === 'No' ? '#B71D18' : '#118D57',
            backgroundColor:
              params.value === 'No'
                ? 'rgba(255, 86, 48, 0.16)'
                : 'rgba(34, 197, 94, 0.16)',
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: 'Detalle',
      field: 'accountNumber',
      minWidth: accountNumberMaxLength * 10,
      flex: 1,
      valueGetter: (value, row) => row.employeeBankAccount?.accountNumber || '',
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      headerName: 'Propietario',
      field: 'ownerName',
      minWidth: 143,
      flex: 1,
      valueGetter: (value, row) => row.employeeBankAccount?.ownerName || '',
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      getActions: (params: GridRowParams<EmployeePaymentType>) => [
        <GridActionsCellItem
          sx={{ p: 0, m: 0 }}
          icon={<EditIcon />}
          label='Editar'
          className='textPrimary'
          onClick={() => handleEditRow(params.row)}
          color='inherit'
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label='Eliminar'
          onClick={() => handleDeletePaymentType(params.id)}
          color='inherit'
        />,
      ],
    },
  ]

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(`/empleados/detalles/${id}`, { state: { id: id } })
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Editar empleado</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Grid container sx={{ width: '100%' }}>
        {isLoading && <Loading centered={true} />}
        <Grid size={{ xs: 12, md: 3.8 }} sx={{ mr: { md: 2 }, mb: { xs: 3 } }}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ mt: 2 }}
              >
                <Avatar
                  src={
                    selectedImageUrl ||
                    (employee?.imageUrl
                      ? `${employee?.imageUrl}?${new Date().getTime()}`
                      : undefined)
                  }
                  alt='User Avatar'
                  sx={{ width: 180, height: 180, mb: 2 }}
                />
                <input
                  accept='image/*'
                  type='file'
                  id='image-upload'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <label htmlFor='image-upload'>
                  <Button variant='contained' color='inherit' component='span'>
                    Seleccionar imagen
                  </Button>
                </label>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                width='100%'
                sx={{ mt: 2 }}
              ></Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <Box
            component='form'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Identificación'
                      value={idDocument}
                      onChange={(e) => setIdDocument(e.target.value)}
                      required
                      error={idDocumentError}
                      helperText={
                        idDocumentError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      label='Fecha de nacimiento'
                      value={birthDate}
                      onChange={handleDateChange}
                      sx={{ width: '100%' }}
                      onError={(newError) => setDateError(newError)}
                      slotProps={{
                        textField: {
                          error: isDateEmpty || dateError != null,
                          helperText: dateErrorMessage,
                        },
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Nombre'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      error={firstNameError}
                      helperText={
                        firstNameError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Apellidos'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      error={lastNameError}
                      helperText={
                        lastNameError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel id='gender-label'>Género</InputLabel>
                      <Select
                        labelId='gender-label'
                        value={gender}
                        label='Género'
                        onChange={(e) =>
                          setGender(e.target.value as EmployeeGender)
                        }
                        required
                      >
                        {Object.values(EmployeeGender).map((gender) => (
                          <MenuItem key={gender} value={gender}>
                            {getGenderDescription(gender)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Dirección'
                      name='address'
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      value={address}
                      multiline
                      maxRows={3}
                      error={addressError}
                      helperText={
                        addressError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={12} container>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                  <Grid size={12}>
                    <Card>
                      <CardHeader
                        title='Perfiles del empleado'
                        subheader={`${selectedTenants.length}/${tenants.length} seleccionados`}
                      />
                      <Divider />
                      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {tenants.map((tenant) => {
                          const isSelected = selectedTenants.includes(tenant.id)
                          return (
                            <ListItemButton
                              key={tenant.id}
                              onClick={() => handleToggleTenant(tenant.id)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge='start'
                                  checked={isSelected}
                                  tabIndex={-1}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText primary={tenant.name} />
                            </ListItemButton>
                          )
                        })}
                      </List>
                    </Card>
                  </Grid>
                  <Grid size={12} container>
                    <Divider style={{ width: '100%' }} />
                  </Grid>
                  <Grid container spacing={0} sx={{ width: '100%' }}>
                    <Grid size={{ xs: 12, md: 8 }}>
                      <Typography variant='h6'>Métodos de pago</Typography>
                    </Grid>
                    <Grid
                      size={{ xs: 12, md: 4 }}
                      sx={{
                        display: 'flex',
                        justifyContent: { xs: 'flex-start', md: 'flex-end' },
                      }}
                    >
                      <Button
                        onClick={() => setOpenAddPaymentTypeDialog(true)}
                        variant='contained'
                        color='inherit'
                        startIcon={<Iconify icon='eva:plus-fill' />}
                      >
                        Agregar
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid size={12}>
                    <DataGrid
                      rows={employeePaymentTypes || []}
                      columns={columns}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableRowSelectionOnClick
                      density='compact'
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions
                sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}
              >
                <Button type='submit' variant='contained' color='inherit'>
                  Guardar
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <DynamicDialog
        openDialog={openAddPaymentTypeDialog}
        dialogTitle={
          editMode ? 'Editar Método de Pago' : 'Agregar Método de Pago'
        }
        onCloseAction={() => {
          setOpenAddPaymentTypeDialog(false)
          setEditMode(false)
          setSelectedPaymentType(null)
        }}
        buttonAccept={{
          text: 'Aceptar',
          action: () => setOpenAddPaymentTypeDialog(false),
        }}
        buttonCancel={{
          text: 'Cancelar',
          action: () => setOpenAddPaymentTypeDialog(false),
        }}
      >
        <EditEmployeePaymentTypeDialog
          open={openAddPaymentTypeDialog}
          onClose={() => {
            setOpenAddPaymentTypeDialog(false)
            setEditMode(false)
            setSelectedPaymentType(null)
          }}
          onSave={editMode ? handleEditPaymentType : handleAddPaymentType}
          editMode={editMode}
          paymentType={selectedPaymentType}
        />
      </DynamicDialog>
    </Container>
  )
}

export default EmployeeEditView
