import { Divider } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react'
import { ApiError } from '../../api/axios'
import { endpoints } from '../../api/constants'
import SearchBar from '../../components/searchbar'
import TenantSelector from '../../components/TenantSelector'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import useAxiosPrivate, { AxiosError } from '../../hooks/useAxiosPrivate'
import { Farm } from '../../types/Farm'
import FarmCard from './FarmCard'
import FarmCardSkeletons from './FarmCardSkeletons'

const HomeView: FC = () => {
  const axiosPrivate = useAxiosPrivate() 
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [farms, setFarms] = useState<Farm[]>([])
  const [filteredFarms, setFilteredFarms] = useState<Farm[]>(farms)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { auth, selectedTenant, isAllTenantsSelected } = useAuth()
  const { tenants } = auth
  const { showError } = useSnackbar()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const getFarms = async () => {
      try {        
        const requestUrl = isAllTenantsSelected || !selectedTenant ? endpoints.getFarms : `${endpoints.getFarms}${selectedTenant?.id}`
        const response = await axiosPrivate.get(requestUrl, {
          signal: controller.signal,
        })
        if (isMounted) {
          setFarms(response.data)
        }
      } catch (err: any) {
       const error = err as AxiosError<ApiError>
       if (error.name !== 'CanceledError') {
         showError('Error cargar los datos: ' + error?.response?.data?.detail)
       }
      }
    }

    getFarms().finally(() => {
      if (isMounted) setIsLoading(false)
    })

    return () => {
      isMounted = false
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant?.id, isAllTenantsSelected])

  const handleSearchSelect = (event: ChangeEvent<{}>, farm: Farm) => {
    if (farm) {
      setSearchTerm(farm.name)
    } else {
      setSearchTerm('')
    }
  }

  useEffect(() => {
    if (searchTerm) {
      const results = farms.filter((farm) =>
        farm.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredFarms(results)
    } else {
      setFilteredFarms(farms)
    }
  }, [searchTerm, farms])

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Typography variant='h4'>
          {isAllTenantsSelected
            ? 'Fincas en todos los perfiles'
            : `Fincas ${selectedTenant?.name}`}
        </Typography>
      </Stack>

      <Grid container direction='column' mb={5}>
        <Grid size={{ xs: 12, md: 6 }} sx={{ mb: 1 }}>
          <TenantSelector />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <SearchBar
            options={farms}
            getOptionLabel={(option: Farm) => option.name}
            isOptionEqualToValue={(option: Farm, value: Farm) =>
              option.id === value.id
            }
            placeholder='Buscar fincas...'
            onChange={handleSearchSelect}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {isLoading ? (
          <FarmCardSkeletons count={5} />
        ) : (
          filteredFarms.map((farm: Farm, index: number) => {
            const tenant = isAllTenantsSelected
              ? tenants?.find((t) => t.id === farm.tenantId)
              : null
            const showDivider =
              tenant &&
              (index === 0 ||
                filteredFarms[index - 1].tenantId !== farm.tenantId)

            return (
              <Fragment key={farm.id}>
                {showDivider && (
                  <Grid size={{ xs: 12 }}>
                    <Divider>
                      <Typography variant='h6'>{tenant.name}</Typography>
                    </Divider>
                  </Grid>
                )}
                <FarmCard farm={farm} />
              </Fragment>
            )
          })
        )}
      </Grid>
    </Container>
  )
}

export default HomeView