import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmDetailsView from '../../sections/Farm/FarmDetailsView'

const FarmDetails = () => {    
    return (
      <>
        <Helmet>
          <title>Finca Detalles | Sistema Planilla</title>
        </Helmet>
  
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <FarmDetailsView />
        </MsalAuthenticationTemplate>
      </>
    )

}

export default FarmDetails