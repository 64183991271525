import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import EmployeeDetailsView from '../../sections/Employee/EmployeeDetailsView'

const EmployeeDetails = () => {    
    return (
      <>
        <Helmet>
          <title>Empleado Detalles | Sistema Planilla</title>
        </Helmet>
  
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <EmployeeDetailsView />
        </MsalAuthenticationTemplate>
      </>
    )

}

export default EmployeeDetails