import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AxiosError } from 'axios'
import { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import { Tenant } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { PaymentType } from '../../types/Employee'
import { Farm } from '../../types/Farm'
import { EmployeeJobType } from '../../types/Journal'
import { setLocalStorageItem } from '../../utils/localStorageUtils'
import { payrollFiltersConstants } from './PayrollFiltersConstants'

interface PayrollFiltersProps {   
  tenants: Tenant[] 
  selectedTenant: Tenant | null
  setSelectedTenant: (tenant: Tenant | null) => void
  farms: Farm[]
  selectedJobTypeIds: number[]
  setSelectedJobTypeIds: (jobTypeIds: number[]) => void
  employeeJobTypes: EmployeeJobType[]
  selectedFarm: Farm | null
  setSelectedFarm: (farm: Farm | null) => void
  selectedPaymentType: PaymentType | null
  setSelectedPaymentType: (paymentType: PaymentType | null) => void
  selectedFromDate?: Dayjs | null 
  onFromDateChange?: (date: Dayjs | null) => void
  selectedToDate?: Dayjs | null 
  onToDateChange?: (date: Dayjs | null) => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const PayrollFilters: FC<PayrollFiltersProps> = ({
  tenants,
  selectedTenant,
  setSelectedTenant,
  farms,
  selectedJobTypeIds,
  setSelectedJobTypeIds,
  employeeJobTypes,
  selectedFarm,
  setSelectedFarm,
  selectedPaymentType,
  setSelectedPaymentType,
  selectedFromDate,
  onFromDateChange,
  selectedToDate,
  onToDateChange,
}) => {
  const [tempSelectedJobTypeIds, setTempSelectedJobTypeIds] = useState<number[]>(selectedJobTypeIds)
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([])  
  const { showError } = useSnackbar()
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()    

    const getPaymentTypes = async () => {
      try {
        const response = await axiosPrivate.get(
          endpoints.getPaymentTypes,
          { signal: controller.signal }
        )
        if (isMounted) {
          setPaymentTypes(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al cargar los tipos de pago')
        }
      }
    }
       
    getPaymentTypes() 
  
    return () => {
      isMounted = false
      controller.abort()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants])

  const handleSelectedJobTypeTempChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event
    setTempSelectedJobTypeIds(
      typeof value === 'string' ? value.split(',').map(item => parseInt(item, 10)) : value,
    )
  }

  const handleSelectedJobTypeClose = () => {
    setSelectedJobTypeIds(tempSelectedJobTypeIds)
  }

  const handleTenantChange = (event: any, newValue: Tenant | null) => {    
    if (newValue?.id !== selectedTenant?.id) {
      setSelectedTenant(newValue)
      if (newValue?.id !== selectedFarm?.tenantId) {
        setSelectedFarm(null)
      }
      
      if (newValue) {
        setLocalStorageItem(payrollFiltersConstants.selectedTenantStorageKey, JSON.stringify(newValue), 1)
      } else {
        setLocalStorageItem(payrollFiltersConstants.selectedTenantStorageKey, '')
      }
    }
  }

  const handleFarmChange = (event: any, newValue: Farm | null) => {
    setSelectedFarm(newValue)
    if (newValue) {
      setLocalStorageItem(payrollFiltersConstants.selectedFarmStorageKey, JSON.stringify(newValue), 1)
    } else {
      setLocalStorageItem(payrollFiltersConstants.selectedFarmStorageKey, '')
    }
  }

  const handlePaymentTypeChange = (event: any, newValue: PaymentType | null) => {
    setSelectedPaymentType(newValue)
    if (newValue) {
      setLocalStorageItem(payrollFiltersConstants.selectedPaymentTypeStorageKey, JSON.stringify(newValue), 1)
    } else {
      setLocalStorageItem(payrollFiltersConstants.selectedPaymentTypeStorageKey, '')
    }
  }

  const handleFromDateChange = (newDate: Dayjs | null) => {
    onFromDateChange && onFromDateChange(newDate)
    if (newDate) {
      setLocalStorageItem(payrollFiltersConstants.selectedFromDateStorageKey, JSON.stringify(newDate), 1)
    } else {
      setLocalStorageItem(payrollFiltersConstants.selectedFromDateStorageKey, '')
    }
  }

  const handleToDateChange = (newDate: Dayjs | null) => {
    onToDateChange && onToDateChange(newDate)
    if (newDate) {
      setLocalStorageItem(payrollFiltersConstants.selectedToDateStorageKey, JSON.stringify(newDate), 1)
    } else {
      setLocalStorageItem(payrollFiltersConstants.selectedToDateStorageKey, '')
    }
  }  

  useEffect(() => {
    setIsAccordionExpanded(
      !!(        
        selectedJobTypeIds.length ||
        selectedFarm ||
        selectedPaymentType ||
        selectedFromDate ||
        selectedToDate
      )
    )
  }, [
    selectedJobTypeIds,
    selectedFarm,
    selectedPaymentType,
    selectedFromDate,
    selectedToDate
  ])

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setIsAccordionExpanded(isExpanded)
  }

  return (
    <div style={{ marginBottom: '40px' }}>
      <Grid container direction='column'>
        <Grid size={{ xs: 12, md: 4 }} sx={{ mb: 1 }}>
          <Autocomplete
            size='small'
            sx={{ minWidth: 200 }}
            fullWidth
            disablePortal
            id='combo-box-perfil'
            options={tenants}
            getOptionLabel={(tenant) => tenant.name}
            isOptionEqualToValue={(option: Tenant, value: Tenant) =>
              option.id === value.id
            }
            value={selectedTenant}
            onChange={handleTenantChange}
            renderInput={(params) => <TextField {...params} label='Perfil' />}
          />
        </Grid>
        <Accordion
          expanded={isAccordionExpanded}
          onChange={handleAccordionChange}
          sx={{ borderRadius: '12px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
            sx={{
              padding: '1',
              minHeight: '0 !important',
              '& .MuiAccordionSummary-content': { margin: '8px 0' },
            }}
          >
            <Typography
              fontWeight='bold'
              sx={{ fontSize: '0.900rem', lineHeight: '1.2' }}
            >
              Filtrar
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={0.7} sx={{ my: 0, mx: 1, p: 0 }}>
              <Grid size={{ xs: 12, md: 4 }}>
                <Autocomplete
                  size='small'
                  sx={{
                    minWidth: 200,
                    ml: { xs: 0, md: 2 },
                    mt: { xs: 1, md: 0 },
                  }}
                  fullWidth
                  disablePortal
                  id='combo-box-finca'
                  options={farms}
                  getOptionLabel={(farm) => farm.name}
                  isOptionEqualToValue={(option: Farm, value: Farm) =>
                    option.id === value.id
                  }
                  value={selectedFarm}
                  onChange={handleFarmChange}
                  renderInput={(params) => (
                    <TextField {...params} label='Finca' />
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <FormControl
                  fullWidth
                  sx={{
                    minWidth: 200,
                    ml: { xs: 0, md: 2 },
                    mt: { xs: 1, md: 0 },
                  }}
                >
                  <InputLabel id='demo-multiple-checkbox-label'>
                    Trabajo
                  </InputLabel>
                  <Select
                    size='small'
                    fullWidth
                    labelId='demo-multiple-checkbox-label'
                    id='demo-multiple-checkbox'
                    multiple
                    value={tempSelectedJobTypeIds}
                    onChange={handleSelectedJobTypeTempChange}
                    onClose={handleSelectedJobTypeClose}
                    input={<OutlinedInput label='Trabajo' />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            employeeJobTypes.find((job) => job.id === id)?.name
                        )
                        .join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {employeeJobTypes.map((jobType) => (
                      <MenuItem key={jobType.id} value={jobType.id}>
                        <Checkbox
                          checked={tempSelectedJobTypeIds.includes(jobType.id)}
                        />
                        <ListItemText primary={jobType.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <Autocomplete
                  size='small'
                  sx={{
                    minWidth: 200,
                    ml: { xs: 0, md: 2 },
                    mt: { xs: 1, md: 0 },
                  }}
                  fullWidth
                  disablePortal
                  id='combo-box-tipo-pago'
                  options={paymentTypes}
                  getOptionLabel={(payment) => payment.name}
                  isOptionEqualToValue={(
                    option: PaymentType,
                    value: PaymentType
                  ) => option.id === value.id}
                  value={selectedPaymentType}
                  onChange={handlePaymentTypeChange}
                  renderInput={(params) => (
                    <TextField {...params} label='Tipo de pago' />
                  )}
                />
              </Grid>
              {selectedFromDate !== undefined && onFromDateChange && (
                <Grid size={{ xs: 12, md: 4 }}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    label='Fecha desde'
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                    }}
                    sx={{
                      width: '100%',
                      ml: { xs: 0, md: 2 },
                      mt: { xs: 1, md: 0 },
                      '.MuiInputBase-root': {
                        // size='small'
                        height: '40px',
                        fontSize: '0.875rem',
                      },
                      '.MuiInputLabel-root': {
                        fontSize: '0.875rem',
                      },
                    }}
                  />
                </Grid>
              )}
              {selectedToDate !== undefined && onToDateChange && (
                <Grid size={{ xs: 12, md: 4 }}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    label='Fecha hasta'
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    slotProps={{
                      actionBar: {
                        actions: ['clear', 'accept'],
                      },
                    }}
                    sx={{
                      width: '100%',
                      ml: { xs: 0, md: 2 },
                      mt: { xs: 1, md: 0 },
                      '.MuiInputBase-root': {
                        // size='small'
                        height: '40px',
                        fontSize: '0.875rem',
                      },
                      '.MuiInputLabel-root': {
                        fontSize: '0.875rem',
                      },
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  )
}

export default PayrollFilters