import { Typography } from "@mui/material"
import Divider from "@mui/material/Divider"
import Grid from '@mui/material/Grid2'
import TextField from "@mui/material/TextField"
import { FC, useEffect, useState } from "react"
import { useAuth } from "../../context/AuthProvider"
import { useDailyWorkReport } from '../../context/DailyWorkReportProvider'
import { getDisplayName } from '../../types/Employee'
import { JournalDailyWorkProduct } from "../../types/Product"
import { fNumber } from '../../utils/format-number'
import { formatFullDateWithDay } from "../../utils/format-time"

const ValidateWorkDetails: FC = () => {
    const {
        selectedFarm,
        selectedFarmProject,
        selectedFarmJobTypes,
        selectedDate,
        observations,
        journalDailyWorkProducts,
        journalDailyWorkDetails,
    } = useDailyWorkReport()
    const [selectedFarmJobTypeNames, setSelectedFarmJobTypeNames] = useState<string>()
    const [selectedJournalDailyWorkProducts, setSelectedJournalDailyWorkProducts] = useState<string>()
    const { selectedTenant } = useAuth()

    useEffect(() => {
      setSelectedFarmJobTypeNames(selectedFarmJobTypes
          .map((jobType) => jobType.name)
          .join(', ')
      )
    }, [selectedFarmJobTypes])   

    useEffect(() => {
      const formatProductDetail = (detail: JournalDailyWorkProduct) => {
        const { productQuantity, productInventory } = detail
        const measureName = productQuantity > 1 ? `${productInventory.productMeasure.name}s` : productInventory.productMeasure.name
        return `${productInventory.name} ${productQuantity} ${measureName}`
      }
    
      const formattedDetails = journalDailyWorkProducts
        .map(formatProductDetail)
        .join(', ')
    
      setSelectedJournalDailyWorkProducts(formattedDetails);
    }, [journalDailyWorkProducts])

    
  return (
    <Grid container spacing={2} sx={{ mt: 1, mb: 4 }}>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextField
          size='small'
          id='outlined-read-only-perfil'
          label='Perfil'
          value={selectedTenant?.name}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextField
          size='small'
          id='outlined-read-only-finca'
          label='Finca'
          value={selectedFarm?.name}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>
      {selectedFarmProject && (
        <Grid size={{ xs: 12, md: 6 }}>
          <TextField
            size='small'
            id='outlined-read-only-project'
            label='Proyecto'
            value={selectedFarmProject?.name}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
          />
        </Grid>
      )}
      <Grid size={{ xs: 12, md: 6 }}>
        <TextField
          size='small'
          id='outlined-read-only-date'
          label='Fecha'
          value={formatFullDateWithDay(selectedDate)}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid size={12}>
        <TextField
          size='small'
          id='outlined-read-only-job-types'
          label='Trabajos realizados'
          value={selectedFarmJobTypeNames}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          multiline
          maxRows={3}
          InputLabelProps={{
            shrink: true, // force the label to shrink
          }}
        />
      </Grid>
      { selectedJournalDailyWorkProducts && 
        <Grid size={12}>
        <TextField
          size='small'
          id='outlined-read-only-products'
          label='Productos utilizados'
          value={selectedJournalDailyWorkProducts}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          multiline
          maxRows={3}
          InputLabelProps={{
            shrink: true, // force the label to shrink
          }}
        />
      </Grid>}
      {observations && (
        <Grid size={12}>
          <TextField
            size='small'
            id='outlined-read-only-observations'
            label='Observaciones'
            value={observations}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            multiline
            maxRows={3}
            InputLabelProps={{
              shrink: true, // force the label to shrink
            }}
          />
        </Grid>
      )}
      <Grid size={12} container sx={{ mx: 1, mt:1, mb:1 }}>
        <Divider style={{width:'100%'}} />
      </Grid>
      
      {journalDailyWorkDetails.map((item, index) => (
        <Grid size={12} container spacing={0} sx={{ ml: 1 }} key={index}>
          <Grid size={{ xs: 12, md: 3 }}>
              <Typography fontWeight="bold">{getDisplayName(item.employee)}</Typography>
          </Grid>
          <Grid size={{ xs: 7, md: 3 }}>
              <Typography >{item.employeeJobType?.name}</Typography>
          </Grid>
          <Grid size={{ xs: 2, md: 1 }}>
            <Typography >{fNumber(item.jobQuantity)}</Typography>
          </Grid>
          <Grid size={{ xs: 3, md: 2 }}>
            <Typography >x {fNumber(item.jobPrice)}</Typography>
          </Grid>
          <Divider light={true} style={{width:'100%'}} />
        </Grid>
      ))}     
    </Grid>
  )
}

export default ValidateWorkDetails