import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { FC } from 'react'
import {
  FarmStatus,
  getFarmStatusLabel,
} from '../../types/Farm'

interface FarmFiltersProps {
  selectedFarmStatus: FarmStatus
  onFarmStatusChange: (newStatus: FarmStatus) => void   
}

const FarmFilters: FC<FarmFiltersProps> = ({
  selectedFarmStatus,
  onFarmStatusChange, 
}) => {  
  const selectedFarmStatusString = selectedFarmStatus.toString()  
  
  const handleFarmStatusChange = (event: SelectChangeEvent) => {
    const newStatus = parseInt(event.target.value, 10)
    onFarmStatusChange(newStatus)
  }  

  return (
    <div style={{ marginBottom: '40px' }}>
      <Accordion defaultExpanded={true} sx={{ borderRadius: '12px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{
            padding: '1',
            minHeight: '0 !important',
            '& .MuiAccordionSummary-content': { margin: '8px 0' },
          }}
        >
          <Typography
            fontWeight='bold'
            sx={{ fontSize: '0.900rem', lineHeight: '1.2' }}
          >
            Filtrar
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={0.7} sx={{ my: 0, mx: 1, p: 0 }}>
            <Grid size={{ xs: 12, md: 4 }}>
              <FormControl
                sx={{
                  minWidth: 200,
                  ml: { xs: 0, md: 2 },
                  mt: { xs: 1, md: 0 },
                }}
                size='small'
                fullWidth
              >
                <InputLabel id='estado-empleado-select-label'>
                  Estado
                </InputLabel>
                <Select
                  labelId='estado-empleado-select-label'
                  id='estado-empleado-select'
                  value={selectedFarmStatusString}
                  label='Estado'
                  onChange={handleFarmStatusChange}
                >
                  <MenuItem value={FarmStatus.Active.toString()}>
                    {getFarmStatusLabel(
                      FarmStatus.Active
                    )}
                  </MenuItem>
                  <MenuItem value={FarmStatus.Inactive.toString()}>
                    {getFarmStatusLabel(
                      FarmStatus.Inactive
                    )}
                  </MenuItem>
                  <MenuItem value={FarmStatus.All.toString()}>
                    {getFarmStatusLabel(FarmStatus.All)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FarmFilters
