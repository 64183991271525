import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import RefreshIcon from '@mui/icons-material/Refresh'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import DialogContentText from '@mui/material/DialogContentText'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Loading from '../../components/Loading/Loading'
import StyledMenu from '../../components/StyledMenu'
import { useAuth } from '../../context/AuthProvider'
import { usePayrollPay } from '../../context/PayrollPayProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { PayrollStatus } from '../../types/Payroll'
import { formatFullDateWithDay } from '../../utils/format-time'
import PayrollPayIncluded from './PayrollPayIncluded'
import PayrollPayNotIncluded from './PayrollPayNotIncluded'

const MIN_LOADING_TIME = 500

interface PayrollPayViewProps {
  payrollId: number
  payrollDate: Date
}

type ApprovePayrollRequest = {
  userId: number
  payrollMasterId: number
}

type ApprovePayrollResponse = {
  id: number
  payrollStatus: PayrollStatus
}

const PayrollPayView: FC<PayrollPayViewProps> = ({
  payrollId,
  payrollDate,
}) => {
  const [value, setValue] = useState('1')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [anchorOptionsMenu, setAnchorOptionsMenu] = useState<null | HTMLElement>(null)
  const openMenuOptions = Boolean(anchorOptionsMenu)
  const [openDeletePayrollDialog, setOpenDeletePayrollDialog] = useState<boolean>(false)
  const [openClosePayrollDialog, setOpenClosePayrollDialog] = useState<boolean>(false)
  const { showSuccess, showError } = useSnackbar()
  const { auth } = useAuth() 
  const [showApprovePayrollFeature, setShowApprovePayrollFeature] = useState<boolean>(false)
  const [payrollApprovedByCurrentUser, setPayrollApprovedByCurrentUser] = useState<boolean>(false)
  const [payrollApprovedByAllApprovers, setPayrollApprovedByAllApprovers] = useState<boolean>(false)
  const [payrollOptionsEnabled, setPayrollOptionsEnabled] = useState<boolean>(true)
  const { setPayrollUpdated } = usePayrollPay()
    
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorOptionsMenu(event.currentTarget)
  }

  const handleOptionsMenuClose = () => {
    setAnchorOptionsMenu(null)
  }

  const handleDeletePayroll = () => {
    setAnchorOptionsMenu(null)
    setOpenDeletePayrollDialog(true)
  }

  const handleClosePayroll = () => {
    setAnchorOptionsMenu(null)
    setOpenClosePayrollDialog(true)
  }

  const handleApprovePayroll = () => {
    const approvePayrollRequest: ApprovePayrollRequest = {
      userId: auth.user?.userid || 0,
      payrollMasterId: payrollId,
    }

    setAnchorOptionsMenu(null)
    ApprovePayroll(approvePayrollRequest)
  }

  const handleOnCloseDeletePayrollDialog = () => {
    setOpenDeletePayrollDialog(false)
  }

  const handleOnCloseClosePayrollDialog = () => {
    setOpenClosePayrollDialog(false)
  }

  const handleOnConfirmDeletePayrollDialog = async () => {
    if (payrollId !== null) {
      await DeletePayroll(payrollId)
      setOpenDeletePayrollDialog(false)
    }
  }

  const handleOnConfirmClosePayrollDialog = async () => {
    if (payrollId !== null) {
      await ClosePayroll(payrollId)
      setOpenClosePayrollDialog(false)
    }
  }

  const ApprovePayroll = async (
    approvePayrollRequest: ApprovePayrollRequest
  ) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendApprovePayrollRequest = async () => {
      try {
        const response = await axiosPrivate.post(
          endpoints.approvePayroll,
          approvePayrollRequest,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess('Planilla approbada exitosamente!')
          setPayrollApprovedByCurrentUser((prev) => !prev)
          setShowApprovePayrollFeature(false)
          const approvePayrollResponse = response.data as ApprovePayrollResponse
          setPayrollApprovedByAllApprovers(approvePayrollResponse.payrollStatus === PayrollStatus.Approved)
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {         
          showError(
            'Error al aprobar la planilla ' + error?.response?.data?.detail, false
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendApprovePayrollRequest()
  }

  const DeletePayroll = async (payrollId: number) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendDeletePayrollRequest = async () => {
      try {
        const url = endpoints.deletePayroll
          .replace('{payrollId}', payrollId.toString())
          .replace('{deletedByUserId}', auth.user?.userid.toString() || '0')

        const response = await axiosPrivate.delete(url, {
          signal: controller.signal,
        })

        if (response.status === 200) {
          showSuccess('Planilla eliminada correctamente!')
          setTimeout(() => {
            navigate(0)
          }, MIN_LOADING_TIME)
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error eliminar la planilla ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendDeletePayrollRequest()
  }

  const ClosePayroll = async (payrollId: number) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendClosePayrollRequest = async () => {
      try {
        const url = endpoints.closePayroll
          .replace('{payrollId}', payrollId.toString())
          .replace('{closedByUserId}', auth.user?.userid.toString() || '0')

        const response = await axiosPrivate.post(url, {
          signal: controller.signal,
        })

        if (response.status === 200) {
          showSuccess('Planilla cerrar exitosamente!')
          setTimeout(() => {
            navigate('/planilla')
          }, MIN_LOADING_TIME)
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error cerrar la planilla ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendClosePayrollRequest()
  }

  return (
    <Container>      
      <Stack
        sx={{ mb: 2 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>
              Pagando Planilla del {formatFullDateWithDay(payrollDate)}
            </Typography>
          </Grid>

          <Grid
            size={{ xs: 12, md: 4 }}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', md: 'flex-end' },
            }}
          >
            <Button
              id='options-button'
              disabled={!payrollOptionsEnabled}
              aria-controls={openMenuOptions ? 'options-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openMenuOptions ? 'true' : undefined}
              variant='contained'
              color='inherit'
              disableElevation
              onClick={handleOptionsMenuClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Opciones
            </Button>
            <StyledMenu
              id='options-menu'
              MenuListProps={{
                'aria-labelledby': 'options-button',
              }}
              anchorEl={anchorOptionsMenu}
              open={openMenuOptions}
              onClose={handleOptionsMenuClose}
            >
               <MenuItem
                  key='refresh'
                  onClick={() => {
                    setPayrollUpdated((prev) => prev + 1)
                    handleOptionsMenuClose()
                  }}
                  disableRipple
                >
                  <RefreshIcon style={{ color: 'green' }} />
                  Actualizar
                </MenuItem>
                <Divider key='divider1' sx={{ my: 0.5 }} />

              {showApprovePayrollFeature && [
                <MenuItem
                  key='approve'
                  onClick={handleApprovePayroll}
                  disableRipple
                >
                  <ThumbUpAltIcon style={{ color: 'green' }} />
                  Aprobar Planilla
                </MenuItem>,
                <Divider key='divider1' sx={{ my: 0.5 }} />,
              ]}

              {auth.user?.isAdmin && [
                <MenuItem
                  key='close'
                  onClick={handleClosePayroll}
                  disableRipple
                >
                  <PriceCheckIcon style={{ color: 'green' }}/>
                  Cerrar Planilla
                </MenuItem>,
                <Divider key='divider2' sx={{ my: 0.5 }} />,
                <MenuItem
                  key='delete'
                  onClick={handleDeletePayroll}
                  disableRipple
                >
                  <DeleteIcon style={{ color: 'red' }} />
                  Eliminar Planilla
                </MenuItem>,
              ]}
            </StyledMenu>
          </Grid>
        </Grid>
      </Stack>

      <Box sx={{ width: '100%', typography: 'body1' }}>
      {isLoading && <Loading centered={true} />}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label='Incluido en Planilla' value='1' />
              <Tab label='No Incluido en Planilla' value='2' />
            </TabList>
          </Box>

          <Box sx={{ px: 0, py: 3 }} hidden={value !== '1'}>
            <PayrollPayIncluded
              payrollId={payrollId}
              onApprovePayroll={handleApprovePayroll}
              onClosePayroll={() => {}}
              onDeletePayroll={handleDeletePayroll}
              showApprovePayrollFeature={showApprovePayrollFeature}
              setShowApprovePayrollFeature={setShowApprovePayrollFeature}
              payrollApprovedByCurrentUser={payrollApprovedByCurrentUser}
              payrollApprovedByAllApprovers={payrollApprovedByAllApprovers}
              setPayrollOptionsEnabled={setPayrollOptionsEnabled}             
            />
          </Box>

          <Box sx={{ px: 0, py: 3 }} hidden={value !== '2'}>
            <PayrollPayNotIncluded payrollId={payrollId}/>
          </Box>
        </TabContext>
      </Box>

      <DynamicDialog
        openDialog={openDeletePayrollDialog}
        dialogTitle='Confirmar eliminación'
        onCloseAction={handleOnCloseDeletePayrollDialog}
        buttonAccept={{
          text: 'Eliminar',
          action: handleOnConfirmDeletePayrollDialog,
        }}
        buttonCancel={{
          text: 'Cancelar',
          action: handleOnCloseDeletePayrollDialog,
        }}
      >
        <DialogContentText id='generic-dialog-description'>
          ¿Realmente quiere eliminar esta planilla en proceso de pago?
        </DialogContentText>
      </DynamicDialog>

      <DynamicDialog
        openDialog={openClosePayrollDialog}
        dialogTitle='Confirmar cerrar planilla'
        onCloseAction={handleOnCloseClosePayrollDialog}
        buttonAccept={{
          text: 'Cerrar planilla',
          action: handleOnConfirmClosePayrollDialog,
        }}
        buttonCancel={{
          text: 'Cancelar',
          action: handleOnCloseClosePayrollDialog,
        }}
      >
        <DialogContentText id='generic-dialog-description'>
          ¿Realmente quiere cerrar esta planilla y finalizar el proceso de pago? Esta acción no se puede deshacer.
        </DialogContentText>
      </DynamicDialog>
    </Container>
  )
}

export default PayrollPayView
