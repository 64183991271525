import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { FarmJobTypeDetails } from '../../types/Journal'
import { constants } from '../contants'

type EditFarmJobTypeRequest = {
  id: number
  updatedByUserEmail: string
  name: string
}

const FarmJobTypeEditView: FC = () => {
  const { auth } = useAuth()
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showError, showSuccess } = useSnackbar()
  const [farmJobType, setFarmJobType] = useState<FarmJobTypeDetails | null>(null)
  const navigate = useNavigate()
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)

    const getFarmJobTypeDetails = async (controller: AbortController) => {
      try {
        const response = await axiosPrivate.get(
          `${endpoints.getFarmJobType}${id}`,
          { signal: controller.signal }
        )

        if (isMounted) {
          setFarmJobType(response.data)
        }
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError(
            'Error al obtener los detalles del tipo de trabajo de la finca'
          )
        }
      } finally {
        if (isMounted) {
          setIsLoading(false)
        }
      }
    }

    getFarmJobTypeDetails(controller)

    return () => {
      isMounted = false
      controller.abort()
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (farmJobType) {
      setName(farmJobType.name)
    }
  }, [farmJobType])

  const validateRequiredFields = (): boolean => {
    let isValid = true

    if (!name.trim()) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    return isValid
  }

  const EditFarmJobType = async (jobType: EditFarmJobTypeRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const SendEditFarmJobTypeRequest = async () => {
      try {
        const response = await axiosPrivate.put(
          endpoints.editFarmJobType,
          jobType,
          { signal: controller.signal }
        )

        if (response.status === 200) {
          showSuccess('Trabajo actualizado correctamente!')
          navigate('/trabajos-fincas')
        }
      } catch (err: any) {
        const error = err as AxiosError<ApiError>

        if (error.name !== 'CanceledError') {
          showError(
            'Error actualizar el trabajo ' + error?.response?.data?.detail
          )
        }
      } finally {
        setIsLoading(false)
      }
    }

    SendEditFarmJobTypeRequest()
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!validateRequiredFields()) {
      showError('Por favor, complete los campos requeridos.')
      return
    }

    const jobType: EditFarmJobTypeRequest = {
      id: Number(id),
      name,
      updatedByUserEmail: auth.user?.email || '', //TODO: Validate user
    }

    EditFarmJobType(jobType)
  }

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(-1)
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Editar Trabajo</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Box
        component='form'
        autoComplete='off'
        noValidate
        onSubmit={handleSubmit}
      >
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {isLoading && <Loading centered={true} />}
              <Grid size={{ xs: 12 }}>
                <TextField
                  fullWidth
                  label='Descripción'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  error={nameError}
                  helperText={nameError ? constants.requiredFieldMessage : ''}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}>
            <Button type='submit' variant='contained' color='inherit'>
              Guardar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}

export default FarmJobTypeEditView
