import { Avatar, Box, List, ListItem, ListItemText } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import DialogContentText from '@mui/material/DialogContentText'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  DataGrid,
  GridColDef,
} from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { EmployeeDetails, EmployeePaymentType, getFullName, getGenderDescription } from '../../types/Employee'
import { fDateShort, fDateShortNotUtc, formatDateTimeCustom } from '../../utils/format-time'

type SetEmployeeActiveStatusRequest = {
  id: number  
  updatedByUserEmail: string
  active: boolean
}

const EmployeeDetailsView: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { showError, showSuccess } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [employee, setEmployee] = useState<EmployeeDetails | null>(null)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [accountNumberMaxLength, setAccountNumberMaxLength] = useState<number>(0)
  
  const getEmployeeDetails = async (controller: AbortController) => {
    try {
      const response = await axiosPrivate.get(
        `${endpoints.getEmployee}${id}`,
        { signal: controller.signal }
      )
      setIsLoading(false)
      setEmployee(response.data)
    } catch (err: any) {
      setIsLoading(false)
      const error = err as AxiosError
      if (error.name !== 'CanceledError') {
        showError('Error al obtener los detalles del empleado')
      }
    }
  }
  
  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setIsLoading(true)    
  
    if (isMounted) {
      getEmployeeDetails(controller)
    }
  
    return () => {
      isMounted = false
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  

   useEffect(() => {
      if (employee) {        
        const maxLength =
          employee.employeePaymentTypes.length > 0
            ? employee.employeePaymentTypes.reduce((max, type) => {
                const accountNumberLength =
                  type.employeeBankAccount?.accountNumber?.length || 0
                return Math.max(max, accountNumberLength)
              }, 0)
            : 12
        setAccountNumberMaxLength(Math.max(maxLength, 12))
      }
    }, [employee])
  

  const SetEmployeeActiveStatus = async (request: SetEmployeeActiveStatusRequest) => {
      setIsLoading(true)
      const controller = new AbortController()
  
      const SendSetEmployeeActiveStatusRequest = async () => {    
          try {
            const response = await axiosPrivate.put(
              endpoints.setEmployeeActiveStatus,
              request,
              { signal: controller.signal }
            )         
      
            if (response.status === 200) {
              showSuccess('El empleado ha sido actualizado correctamente!')         
              getEmployeeDetails(controller)      
            }
          } catch (err: any) {
            const error = err as AxiosError<ApiError>
      
            if (error.name !== 'CanceledError') {
              showError('Error actualizar el empleado ' + error?.response?.data?.detail)
            }
          } finally {
            setIsLoading(false)
          }
        }
  
        SendSetEmployeeActiveStatusRequest()
    }

  const handleSetEmployeeActiveStatus = () => {
    if (employee) {
      const request: SetEmployeeActiveStatusRequest = {
        id: employee.id,
        active: !employee.isDeleted,
        updatedByUserEmail: auth.user?.email || '', //TODO: Validate user,
      }
      SetEmployeeActiveStatus(request)        
    }
  }

  const handleEditEmployee = () => {
    if (employee) {
      navigate(`/empleados/editar/${id}`, { state: { id: id } })
    }
  }

  const handleOnCloseChangeStatusModal = () => {
    setOpenChangeStatusModal(false)
  }

  const dialogAcceptButton = {
    text: 'Confirmar',
    action: () => {
      handleSetEmployeeActiveStatus()
      setOpenChangeStatusModal(false)
    },
  }

  const dialogCancelButton = {
    text: 'Cancelar',
    action: () => {      
      setOpenChangeStatusModal(false)
    },
  }

  const columns: GridColDef<EmployeePaymentType>[] = [
    {
      headerName: 'Tipo',
      field: 'paymentTypeDescription',
      minWidth: 125,
      flex: 1,
    },
    {
      headerName: 'En uso',
      field: 'isDeleted',
      valueGetter: (value, row) => (row.isDefault ? 'Si' : 'No'),
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === 'No' ? '#B71D18' : '#118D57',
            backgroundColor:
              params.value === 'No'
                ? 'rgba(255, 86, 48, 0.16)'
                : 'rgba(34, 197, 94, 0.16)',
            padding: '5px',
            borderRadius: '3px',
          }}
        >
          {params.value}
        </span>
      ),
    },  
    {
      headerName: 'Detalle',
      field: 'accountNumber',
      minWidth: accountNumberMaxLength * 10,      
      flex: 1,
      valueGetter: (value, row) => row.employeeBankAccount?.accountNumber || '',
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    }, 
    {
      headerName: 'Propietario',
      field: 'ownerName',
      minWidth: 143,      
      flex: 1,
      valueGetter: (value, row) => row.employeeBankAccount?.ownerName || '',
      renderCell: (
        params // Text wrapping
      ) => (
        <div style={{ whiteSpace: 'normal', lineHeight: 'normal' }}>
          {params.value}
        </div>
      ),
    },    
  ]

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate('/empleados')
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Detalles del empleado</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Grid container sx={{ width: '100%' }}>
        {isLoading && <Loading centered={true} />}
        <Grid size={{ xs: 12, md: 3.8 }} sx={{ mr: { md: 2 }, mb: { xs: 3 } }}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ mt: 2 }}
              >
                <Avatar
                 src={employee?.imageUrl ? `${employee?.imageUrl}?${new Date().getTime()}` : undefined}
                  alt='User Avatar'
                  sx={{ width: 180, height: 180, mb: 2 }}
                />               
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                width='100%'
                sx={{ mt: 2 }}
              >
                <Typography
                  component='span'
                  sx={{
                    color: employee?.isDeleted ? '#B71D18' : '#118D57',
                    backgroundColor: employee?.isDeleted
                      ? 'rgba(255, 86, 48, 0.16)'
                      : 'rgba(34, 197, 94, 0.16)',
                    padding: '5px',
                    borderRadius: '3px',
                  }}
                >
                  {employee?.isDeleted ? 'Desactivado' : 'Activo'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {isLoading && <Loading centered={true} />}
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Nombre:</strong> {employee ? getFullName(employee) : ''}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Identificación:</strong> {employee?.idDocument}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Fecha de nacimiento:</strong>{' '}
                    {fDateShortNotUtc(employee?.birthDate)}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Dirección:</strong> {employee?.address}
                  </Typography>
                </Grid>
                {employee?.phoneNumber && (
                  <Grid size={{ xs: 12 }}>
                    <Typography variant='body1'>
                      <strong>Teléfono:</strong> {employee?.phoneNumber}
                    </Typography>
                  </Grid>
                )}
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Género:</strong>{' '}
                    {getGenderDescription(employee?.gender ?? '')}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Fecha ingreso:</strong>{' '}
                    {fDateShort(employee?.createdDate)}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant='body1'>
                    <strong>Última modificación:</strong>{' '}
                    {formatDateTimeCustom(employee?.modifiedDate)} (
                    {employee?.modifiedByUserEmail})
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Typography variant='h6'>Perfiles asociados</Typography>
              <List sx={{ padding: 0 }}>
                {employee?.tenants.length ? (employee?.tenants.map((tenant) => (
                  <ListItem key={tenant.id} sx={{ paddingY: 0 }}>
                    <ListItemText primary={tenant.name} />
                  </ListItem>
                ))) : (
                  <Typography variant='body2' color='textSecondary'>
                    El empleado no tiene perfiles asociados.
                  </Typography>
              )}
              </List>
              <Divider sx={{ my: 2 }} />
              <Typography variant='h6'>Métodos de pago</Typography>
              {employee?.employeePaymentTypes.length ? (
                <Grid size={12}>
                  <DataGrid
                    rows={employee?.employeePaymentTypes || []}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                    density='compact'
                  />
                </Grid>
              ) : (
                <Typography variant='body2' color='textSecondary'>
                  El empleado no tiene métodos de pago asociados.
                </Typography>
              )}
            </CardContent>
            <Divider />
            <CardActions
              sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}
            >
              <Button
                onClick={handleEditEmployee}
                variant='contained'
                color='inherit'
              >
                Editar
              </Button>
              {employee?.isDeleted ? (
                <Button
                  onClick={() => {
                    setOpenChangeStatusModal(true)
                  }}
                  variant='contained'
                  color='success'
                >
                  Activar
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setOpenChangeStatusModal(true)
                  }}
                  variant='contained'
                  color='error'
                >
                  Desactivar
                </Button>
              )}
            </CardActions>

            <DynamicDialog
              openDialog={openChangeStatusModal}
              dialogTitle={`Confirmar ${
                employee?.isDeleted ? 'activación' : 'desactivación'
              }`}
              onCloseAction={handleOnCloseChangeStatusModal}
              buttonAccept={dialogAcceptButton}
              buttonCancel={dialogCancelButton}
            >
              <DialogContentText id='generic-dialog-description'>
                {employee?.isDeleted
                  ? '¿Activar empleado?'
                  : '¿Desactivar empleado?'}
              </DialogContentText>
            </DynamicDialog>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default EmployeeDetailsView
