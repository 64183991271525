import ForestIcon from '@mui/icons-material/Forest'
import {
  Avatar,
  Box,
} from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import Compressor from 'compressorjs'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { FarmDetails } from '../../types/Farm'
import { constants } from '../contants'

type EditFarmRequest = {
  id: number
  updatedByUserEmail: string
  name: string
  address: string
  propertySize: number
}

const FarmEditView: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { showError, showSuccess } = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [farm, setFarm] = useState<FarmDetails | null>(null)  
  const [name, setName] = useState<string>('')  
  const [address, setAddress] = useState<string>('')
  const [propertySize, setPropertySize] = useState<number>(0)
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
  const [selectedImageBytes, setSelectedImageBytes] = useState<File | null>(null)    
  const [nameError, setNameError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<boolean>(false)
  const [propertySizeError, setPropertySizeError] = useState<boolean>(false)
    
  const validateRequiredFields = (): boolean => {
    let isValid = true  

    if (!name.trim()) {
      setNameError(true)
      isValid = false
    } else {
      setNameError(false)
    }

    if (!propertySize || propertySize <= 0) {
      setPropertySizeError(true)
      isValid = false
    } else {
      setPropertySizeError(false)
    }

    if (!address.trim()) {
      setAddressError(true)
      isValid = false
    } else {
      setAddressError(false)
    }

    return isValid
  } 

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        // 1MB
        new Compressor(file, {
          quality: 0.4, // Adjust the quality of the compressed image
          maxWidth: 800, // Resize image if it exceeds 1024px
          success(result: Blob) {
            const compressedFile = new File([result], file.name, {
              type: file.type,
            })
            setSelectedImageBytes(compressedFile)
            setSelectedImageUrl(URL.createObjectURL(compressedFile))
          },
          error(err) {
            console.error('Error al comprimir la imagen:', err)
          },
        })
      } else {
        setSelectedImageBytes(file)
        setSelectedImageUrl(URL.createObjectURL(file))
      }
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    setIsLoading(true)
  
    const getFarmDetails = async () => {
      try {
        const response = await axiosPrivate.get(`${endpoints.getFarm}${id}`, {
          signal: controller.signal,
        })
        setFarm(response.data)
      } catch (err: any) {
        const error = err as AxiosError
        if (error.name !== 'CanceledError') {
          showError('Error al obtener los detalles de la finca')
        }
      } finally {
        setIsLoading(false)
      }
    }
  
    getFarmDetails()
  
    return () => {
      controller.abort()
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (farm) {
      setName(farm.name)
      setAddress(farm.address)           
      setPropertySize(farm.propertySize)
    }
  }, [farm])

  const EditFarm = async (farmDetails: EditFarmRequest) => {
    setIsLoading(true)
    const controller = new AbortController()

    const formData = new FormData()
    formData.append('Id', farmDetails.id.toString())
    formData.append('UpdatedByUserEmail', farmDetails.updatedByUserEmail)
    formData.append('Name', farmDetails.name)    
    formData.append('Address', farmDetails.address)
    formData.append('PropertySize', farmDetails.propertySize.toString())
    if (selectedImageBytes) {
      formData.append('UpdatedImage', selectedImageBytes)
    }

    try {
      const response = await axiosPrivate.put(
        endpoints.editFarm,
        formData,
        {
          signal: controller.signal,
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      if (response.status === 200) {
        showSuccess('Finca actualizada correctamente!')
        navigate(`/fincas/detalles/${id}`, { state: { id: id } })
      }
    } catch (err: any) {
      const error = err as AxiosError<ApiError>
      if (error.name !== 'CanceledError') {
        showError(
          'Error al actualizar la finca: ' + error?.response?.data?.detail
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!validateRequiredFields()) {
      showError('Por favor, complete los campos requeridos.')
      return
    }   

    if (farm?.id === undefined) {
      showError('No se puede editar la finca porque el ID es inválido')
      return
    }

    const farmDetails: EditFarmRequest = {
      id: farm?.id,
      updatedByUserEmail: auth.user?.email || '',
      name,
      address,     
      propertySize,     
    }

    EditFarm(farmDetails)
  }  

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select()
  } 

  return (
    <Container>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        mb={5}
      >
        <Grid container sx={{ width: '100%' }}>
          <Grid
            size={12}
            sx={{
              mb: 2,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              onClick={() => {
                navigate(`/fincas/detalles/${id}`, { state: { id: id } })
              }}
              variant='contained'
              color='inherit'
              startIcon={<Iconify icon='eva:arrow-back-fill' />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Typography variant='h4'>Editar finca</Typography>
          </Grid>
        </Grid>
      </Stack>

      <Grid container sx={{ width: '100%' }}>
        {isLoading && <Loading centered={true} />}
        <Grid size={{ xs: 12, md: 3.8 }} sx={{ mr: { md: 2 }, mb: { xs: 3 } }}>
          <Card>
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ mt: 2 }}
              >
                <Avatar
                  src={
                    selectedImageUrl ||
                    (farm?.imageUrl
                      ? `${farm?.imageUrl}?${new Date().getTime()}`
                      : undefined)
                  }
                  alt='Farm Avatar'
                  sx={{ width: 180, height: 180, mb: 2 }}
                >
                   {!selectedImageUrl && <ForestIcon sx={{ fontSize: 80 }} />}
                </Avatar>
                <input
                  accept='image/*'
                  type='file'
                  id='image-upload'
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                />
                <label htmlFor='image-upload'>
                  <Button variant='contained' color='inherit' component='span'>
                    Seleccionar imagen
                  </Button>
                </label>
              </Box>             
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 8 }}>
          <Box
            component='form'
            autoComplete='off'
            noValidate
            onSubmit={handleSubmit}
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Nombre'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                      error={nameError}
                      helperText={
                        nameError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <TextField
                      fullWidth
                      label='Tamaño (hectáreas)'
                      value={propertySize}
                      type='number'
                      onChange={(e) => setPropertySize(Number(e.target.value))}
                      required
                      onFocus={handleFocus}
                      error={propertySizeError}
                      helperText={
                        propertySizeError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 12}}>
                    <TextField
                      fullWidth
                      label='Dirección'
                      name='address'
                      onChange={(e) => setAddress(e.target.value)}
                      required
                      value={address}
                      multiline
                      maxRows={3}
                      error={addressError}
                      helperText={
                        addressError ? constants.requiredFieldMessage : ''
                      }
                    />
                  </Grid>                 
                </Grid>
              </CardContent>
              <Divider />
              <CardActions
                sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}
              >
                <Button type='submit' variant='contained' color='inherit'>
                  Guardar
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FarmEditView
